/* #region Imports */
import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from "react";
import 'style/sales/checkBill.css';
import Textbox from 'components/tools/Textbox';
import { apiGetBill, apiUpdateBill } from "api/sales/SalesApi";
import ThinButton from "components/tools/ThinButton";
import Checkbox from "components/tools/Checkbox";
import { useSelector } from "react-redux";
/* #endregion Imports */

const CheckBillComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "checkBillForm";
    const isActive = useRef(false);
    const userRights = useSelector(state => state.main.userRights);

    // Search References
    const formData = useRef(null);
    const barcodeTB = useRef();
    const [invoiceNo, setInvoiceNo] = useState();
    const [date, setDate] = useState();
    const [customer, setCustomer] = useState();
    const [total, setTotal] = useState();
    const [paid, setPaid] = useState();
    const [delivered, setDelivered] = useState();
    const autoCheckbox = useRef();

    // Tools References
    const messageBox = props.messageBox;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        barcodeTB.current.focus();

        if (!userRights.payBill) {
            const autoCheckbox = document.querySelector('#checkBillForm .autoCheckbox');
            const paidBtn = document.querySelector('#checkBillForm .paidBtn');
            const notPaidBtn = document.querySelector('#checkBillForm .notPaidBtn');
            autoCheckbox.classList.add("d-none");
            paidBtn.classList.add("d-none");
            notPaidBtn.classList.add("d-none");
        }
    }
    const loadBill = () => {
        const id = barcodeTB.current.getText();
        if (id !== "") {
            apiGetBill({
                id: id,
            }).then((result) => {
                try {
                    if (isActive.current) {
                        if (result.length > 0) {
                            const bill = result[0];

                            if (userRights.payBill && autoCheckbox.current.isChecked() && !bill.isPaid){
                                formData.current = bill;
                                onPaidBtnClick();
                            }
                            else{
                                showDataIntoTextboxes(bill);
                            }
                        }
                        else
                            clearTextboxes();
                    }
                } catch (error) {

                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                }
            });
        }
    }
    const updateBill = (params) => {
        apiUpdateBill(params).then((result) => {
            try {
                if (isActive.current) {
                    showDataIntoTextboxes(result[0]);
                }
            } catch (error) {

            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
            }
        });
    }
    const clearTextboxes = () => {
        barcodeTB.current.setText("");
        setInvoiceNo("");
        setDate("");
        setCustomer("");
        setTotal("");

        setPaid("");
        setDelivered("");

        formData.current = null;

        barcodeTB.current.focus();
    }
    const showDataIntoTextboxes = (bill) => {
        formData.current = bill;

        setInvoiceNo(bill.invoiceNo);
        setDate(`${bill.date} ${bill.time}`);
        setCustomer(bill.customerName);
        setTotal(bill.grandTotal);

        const paidLabel = document.querySelector('#checkBillForm .paidLabel');
        if (bill.isPaid) {
            setPaid("Paid");
            paidLabel.style.color = 'green';
        }
        else {
            setPaid("Not Paid");
            paidLabel.style.color = 'red';
        }

        const deliveredLabel = document.querySelector('#checkBillForm .deliveredLabel');
        if (bill.isDelivered) {
            setDelivered("Delivered");
            deliveredLabel.style.color = 'green';
        }
        else {
            setDelivered("Pending");
            deliveredLabel.style.color = 'red';
        }

        barcodeTB.current.setText("");
        barcodeTB.current.focus();
    }
    /* #endregion */

    /* #region Clicks */
    const onPaidBtnClick = () => {
        if (formData.current !== null) {
            const params = {
                id: formData.current.id,
                isPaid: true,
            }
            updateBill(params);
        }
    }
    const onNotPaidBtnClick = () => {
        if (formData.current !== null) {
            const params = {
                id: formData.current.id,
                isPaid: false,
            }
            updateBill(params);
        }
    }
    const onDeliveredBtnClick = () => {
        if (formData.current !== null) {
            const params = {
                id: formData.current.id,
                isDelivered: true,
            }
            updateBill(params);
        }
    }
    const onPendingBtnClick = () => {
        if (formData.current !== null) {
            const params = {
                id: formData.current.id,
                isDelivered: false,
            }
            updateBill(params);
        }
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
            // close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        }
    }
    const onBarcodeTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadBill();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */

    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'block' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div className="main">
                            <div className="top">
                                <Textbox
                                    ref={barcodeTB}
                                    label="Scan Barcode"
                                    onKeyDown={onBarcodeTBKeydown}
                                    tabIndex={1601} />

                                <div className="dRow" style={{ marginTop: '8px' }}>
                                    <div className="dRow">
                                        <p className="heading">Invoice No:</p>
                                        <p className="label">{invoiceNo}</p>
                                    </div>
                                    <div className="dRow marginLeft">
                                        <p className="heading">Date:</p>
                                        <p className="label">{date}</p>
                                    </div>
                                    <div className="dRow marginLeft">
                                        <p className="heading">Customer:</p>
                                        <p className="label">{customer}</p>
                                    </div>
                                </div>

                                <div className="dRow totalPanel">
                                    <p className="heading">Total:</p>
                                    <p className="label defaultMarginLeft">{total}</p>
                                </div>

                                <div className="dCol paidPanel">
                                    <p className="paidLabel">{paid}</p>
                                    <p className="deliveredLabel">{delivered}</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="dRow">
                                    <ThinButton
                                        label="Delivered"
                                        className="deliveredBtn"
                                        style={{ backgroundColor: 'green' }}
                                        onClick={onDeliveredBtnClick} />
                                    <ThinButton
                                        label="Pending"
                                        className="pendingBtn"
                                        onClick={onPendingBtnClick} />
                                </div>
                                <div className="dRow">
                                    <Checkbox
                                        ref={autoCheckbox}
                                        style={{ marginTop: '24px', marginRight: '8px' }}
                                        checked={true}
                                        className="autoCheckbox"
                                        label="Auto Pay on Scan" />
                                    <ThinButton
                                        label="Not Paid"
                                        className="notPaidBtn"
                                        onClick={onNotPaidBtnClick} />
                                    <ThinButton
                                        label="Paid"
                                        className="paidBtn"
                                        style={{ backgroundColor: 'green' }}
                                        onClick={onPaidBtnClick} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default CheckBillComponent;