import { getBranchGroupId, getUserId, post } from 'utilities/DB.js';

export const apiLoadBarcodeLabels = () => {
    const params = {
        userId: getUserId()
    }
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/loadBarcodeLabels", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertBarcodeLabel = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/insertBarcodeLabel", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBarcodeLabel = (params) => {
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/updateBarcodeLabel", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteBarcodeLabel = (barcodeLabelId) => {
    const params = {
        id: barcodeLabelId
    }
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/deleteBarcodeLabel", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBarcodeLabel = (barcodeLabelId) => {
    const params = {
        id: barcodeLabelId,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/getBarcodeLabel", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeLabelExists = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/isBarcodeLabelExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiClearBarcodeLabel = () => {
    const params = {
        userId: getUserId(),
    }

    return new Promise((resolve, reject) => {
        post("api/barcodeLabels/clearBarcodeLabels", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
