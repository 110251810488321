/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/warehouse/gatepass.css';

import {
    apiLoadGatepasses,
    apiInsertGatepass,
    apiUpdateGatepass,
    apiDeleteGatepass,
    apiGetGatepass,
    apiIsGatepassExists,
    apiLoadGatepass
} from "api/warehouse/GatepassApi";

import {
    apiLoadGatepassItems,
    apiInsertGatepassItem,
    apiUpdateGatepassItem,
    apiDeleteGatepassItem,
    apiGetGatepassItem,
    apiIsGatepassItemExists
} from "api/warehouse/GatepassItemsApi";
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import AutocompleteTextbox from "components/tools/AutocompleteTextbox";
import { convertToNumber } from 'utilities/Utils';
import DateTimePicker from 'components/tools/DateTimePicker';
import WarehouseCB from 'components/tools/dropdowns/WarehouseCB';
import ComboBox from 'components/tools/ComboBox';
import { useReactToPrint } from 'react-to-print';
import GatepassThermal from './GatepassThermal';
import { useSelector } from 'react-redux';
/* #endregion Imports */

const GatepassComponent = (props, ref) => {

    /* #region Variables */
    const formId = "gatepassForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const settings = useSelector(state => state.main.settings);

    const warehouseCB = useRef();
    const gatepassNameTB = useRef();
    // const dateTB = useRef();
    const remarksTB = useRef();
    const searchGatepassTB = useRef();
    const searchDateTB = useRef();

    const [gatepassData, setGatepassData] = useState([]);
    const gatepassCB = useRef();
    const gatepassItemTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const searchGatepassItemsTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableGRef = useRef();
    const tablePIRef = useRef();
    const [totalGatepasses, setTotalGatepasses] = useState(0);
    const [totalGatepassItems, setTotalGatepassItems] = useState(0);

    // Other Variables
    const updateIdG = useRef(0);
    const isUpdateG = useRef(false);
    const updateIdGI = useRef(0);
    const isUpdateGI = useRef(false);
    const itemId = useRef(0);

    const formDataG = useRef(null);
    const formDataGI = useRef(null);

    // eslint-disable-next-line
    const [activeBill, setActiveBill] = useState("thermal");
    const billRef = useRef();
    const [billData, setBillData] = useState([]);

    const columnsG = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Gatepass Name', row: "gatepassName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '150px' } },
    ]);

    const columnsGI = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Gatepass Item', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    /* #region Gatepasses */
    const formLoad = () => {
        // loadGatepasses();
    }
    const loadGatepasses = () => {
        if (tableGRef.current !== null)
            tableGRef.current.setLoading(true);

        apiLoadGatepasses({
            warehouseId: warehouseCB.current.getValue(),
            text: searchGatepassTB.current.getText(),
            date: searchDateTB.current.getText(),
        }).then((result) => {
            try {
                setTotalGatepasses(result.total.totalGatepasses);
                tableGRef.current.setData([...result.rows]);
                loadGatepassesCB(result);
                tableGRef.current.setLoading(false);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadGatepassesCB = (result) => {
        let temp = [
            { id: 0, name: 'None' }
        ];

        const data = temp.concat(result.gatepasses);
        setGatepassData([...data]);
    }
    const inserted = useRef(true);
    const insertOrUpdateGatepass = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationG();

        if (isValid) {
            if (isUpdateG.current) {
                const data = getFormDataG();
                data.id = updateIdG.current;
                apiUpdateGatepass(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Gatepass updated successfully.", "s");
                        loadGatepasses();
                        clearTextboxesG();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertGatepass(getFormDataG())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Gatepass saved successfully.", "s");
                        loadGatepasses();
                        clearTextboxesG();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editGatepass = () => {
        const row = tableGRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetGatepass(tempId)
                .then((result) => {
                    updateIdG.current = tempId;
                    isUpdateG.current = true;

                    setFormDataG(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteGatepass = () => {
        const row = tableGRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Gatepass?")) {
                apiDeleteGatepass(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Gatepass deleted successfully.", "s");
                        loadGatepasses();
                        clearTextboxesG();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteGatepassIsUpdate = () => {
        if (isUpdateG.current) {
            if (window.confirm("Are you sure to delete this Gatepass?")) {
                apiDeleteGatepass(updateIdG.current)
                    .then((result) => {
                        toast.current.show("Gatepass deleted successfully.", "s");
                        loadGatepasses();
                        clearTextboxesG();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesG = () => {
        gatepassNameTB.current.setText("");
        // dateTB.current.setText(new Date());
        remarksTB.current.setText("");

        updateIdG.current = 0;
        isUpdateG.current = false;
        formDataG.current = null;

        gatepassNameTB.current.focus();
    }
    const setFormDataG = (gatepasses) => {
        formDataG.current = gatepasses;

        gatepassNameTB.current.setText(gatepasses.gatepassName);
        // dateTB.current.setText(gatepasses.date);
        remarksTB.current.setText(gatepasses.remarks);

        gatepassNameTB.current.focus();
    }
    const getFormDataG = () => {
        const gatepasses = {
            warehouseId: warehouseCB.current.getValue(),
            gatepassName: gatepassNameTB.current.getText().trim(),
            // date: dateTB.current.getText(),
            remarks: remarksTB.current.getText(),
        }

        return gatepasses;
    }
    const validationG = async () => {
        const gatepassName = gatepassNameTB.current.getText().trim();
        const isValid = await isValidGatepass(gatepassName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidGatepass = async (gatepassName) => {
        if (warehouseCB.current.getValue() === 0) {
            toast.current.show("Please Select Warehouse.", "i");
            return false;
        }
        else if (gatepassName === '') {
            toast.current.show("Please Enter Gatepass Name.", "i");
            gatepassNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateG.current) {
                if (gatepassName.toLowerCase() === formDataG.current.gatepassName.toLowerCase())
                    return true;
                else
                    return isGatepassExists(gatepassName);
            }
            else
                return isGatepassExists(gatepassName);
        }
    }
    const isGatepassExists = async (gatepassName) => {
        const result = await apiIsGatepassExists(gatepassName, new Date(), warehouseCB.current.getValue());
        if (result.isExists) {
            toast.current.show("Gatepass Name already exists.", "i");
            gatepassNameTB.current.focus();
            return false;
        } else
            return true;
    }

    /* #endregion */

    /* #region GatepassesItems */
    const loadGatepassItems = () => {
        const gatepassId = gatepassCB.current.getValue();
        if (gatepassId !== 0) {
            if (tablePIRef.current !== null)
                tablePIRef.current.setLoading(true);

            apiLoadGatepassItems({
                text: searchGatepassItemsTB.current.getText(),
                gatepassId: gatepassId,
            }).then((result) => {
                try {
                    setTotalGatepassItems(result.total.totalGatepassItems);
                    tablePIRef.current.setData([...result.rows]);
                    tablePIRef.current.setLoading(false);
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            tablePIRef.current.clearData();
        }
    }
    const inserted1 = useRef(true);
    const insertOrUpdateGatepassItem = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationGI();

        if (isValid) {
            if (isUpdateGI.current) {
                const data = getFormDataGI();
                data.id = updateIdGI.current;
                apiUpdateGatepassItem(data)
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Item updated successfully.", "s");
                        loadGatepassItems();
                        clearTextboxesGI();
                        // loadGatepasses();
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertGatepassItem(getFormDataGI())
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Item saved successfully.", "s");
                        loadGatepassItems();
                        clearTextboxesGI();
                        // loadGatepasses();
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editGatepassItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetGatepassItem(tempId)
                .then((result) => {
                    updateIdGI.current = tempId;
                    isUpdateGI.current = true;

                    setFormDataGI(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteGatepassItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Item?")) {
                apiDeleteGatepassItem(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Item deleted successfully.", "s");
                        loadGatepassItems();
                        // loadGatepasses();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteGatepassItemIsUpdate = () => {
        if (isUpdateGI.current) {
            if (window.confirm("Are you sure to delete this Item?")) {
                apiDeleteGatepassItem(updateIdGI.current)
                    .then((result) => {
                        toast.current.show("Item deleted successfully.", "s");
                        loadGatepassItems();
                        clearTextboxesGI();
                        // loadGatepasses();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesGI = () => {
        gatepassItemTB.current.setText("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");

        updateIdGI.current = 0;
        isUpdateGI.current = false;
        itemId.current = 0;
        formDataGI.current = null;

        gatepassItemTB.current.focus();
    }
    const setFormDataGI = (gatepassItem) => {
        formDataGI.current = gatepassItem;

        itemId.current = gatepassItem.itemId;
        gatepassItemTB.current.setText(gatepassItem.itemname);
        qtyTB.current.setText(gatepassItem.qty);
        crtnTB.current.setText(gatepassItem.crtn);

        qtyTB.current.focus();
    }
    const getFormDataGI = () => {
        const gatepassItem = {
            gatepassId: gatepassCB.current.getValue(),
            itemId: itemId.current,
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToNumber(crtnTB.current.getText()),
        }

        return gatepassItem;
    }
    const showDataIntoTextboxes = (item) => {
        itemId.current = Number(item.id);
        gatepassItemTB.current.setText(item.itemname);
        qtyTB.current.setText(item.qty);
        crtnTB.current.setText(item.crtn);
        qtyTB.current.focus();
    }
    const validationGI = async () => {
        // const gatepassItemName = gatepassItemTB.current.getText().trim();
        const isValid = await isValidGatepassItem();
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidGatepassItem = async () => {
        if (gatepassCB.current.getValue() === 0) {
            toast.current.show("Please Select Gatepass.", "i");
            return false;
        }
        else if (itemId.current === 0) {
            toast.current.show("Please Select Gatepass Item.", "i");
            gatepassItemTB.current.focus();
            return false;
        }
        else if (convertToNumber(qtyTB.current.getText()) === 0 && convertToNumber(crtnTB.current.getText()) === 0) {
            toast.current.show("Please Enter Quantity.", "i");
            qtyTB.current.focus();
            return false;
        }
        else {
            if (isUpdateGI.current) {
                if (itemId.current === formDataGI.current.itemId)
                    return true;
                else
                    return isGatepassItemExists();
            }
            else
                return isGatepassItemExists();
        }
    }
    const isGatepassItemExists = async () => {
        const result = await apiIsGatepassItemExists({
            itemId: itemId.current,
            gatepassId: gatepassCB.current.getValue()
        });
        if (result.isExists) {
            toast.current.show("Item already exists.", "i");
            gatepassItemTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                const dataDiv = document.getElementById("gatepassData");

                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    printBill(); // Recall untill populated completely
                    return;
                }

                // const ticketElement = document.getElementsByClassName("ticket")[0];
                // ticketElement.style.display = "block";

                // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(ticketElement, options);
                // await exporter.getPdf(options);

                // For Print to Printer
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setBillData([]);
            gatepassItemTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    /* #region Gatepasses */
    const onGatepassSaveBtnClick = () => {
        insertOrUpdateGatepass();
    }

    const onGatepassEditBtnClick = () => {
        editGatepass();
    }

    const onGatepassDeleteBtnClick = () => {
        deleteGatepassIsUpdate();
    }

    const onGatepassCancelBtnClick = () => {
        clearTextboxesG();
    }
    const gatepassesTableOnclick = (row) => {
        const id = Number(row['id']);
        gatepassCB.current.setValue(id);
    }
    const onPrintBtnClick = () => {
        const row = tableGRef.current.getSelectedRow();
        if (row) {
            const id = Number(row['id']);
            apiLoadGatepass(id).then((result) => {
                try {
                    setBillData([...result]);
                    printBill();
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    /* #endregion */

    /* #region GatepassesItems */
    const onGatepassItemSaveBtnClick = () => {
        insertOrUpdateGatepassItem();
    }

    const onGatepassItemEditBtnClick = () => {
        editGatepassItem();
    }

    const onGatepassItemDeleteBtnClick = () => {
        deleteGatepassItemIsUpdate();
    }

    const onGatepassItemCancelBtnClick = () => {
        clearTextboxesGI();
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "warehouse")
            return;

        if (activeTab.current !== "gatepass")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateGatepass();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editGatepass();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteGatepassIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesG();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxesG();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableGRef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateGatepassItem();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editGatepassItem();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteGatepassItemIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesGI();
        } else if (e.altKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onGatepassSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadGatepasses();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableGRef.current.focus();
        }
    }
    const onGatepassItemSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadGatepassItems();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    const onGatepassNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateGatepass();
        }
    }
    const onGatepassItemTBKeydown = (data) => {
        showDataIntoTextboxes(data);
    }
    const onQtyTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            crtnTB.current.focus();
        }
    }
    const onCrtnTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateGatepassItem();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Gatepass Combobox Change
    const onGatepassCBChange = (selectedValue, selectedText) => {
        loadGatepassItems();
    }
    const onGatepassSearchTBChange = () => {
        if (settings.instantSearch)
            loadGatepasses();
    }
    const onGatepassItemSearchTBChange = () => {
        if (settings.instantSearch)
            loadGatepassItems();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "gatepass" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "gatepass" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Gatepass</h4>
                        </div>

                        <WarehouseCB
                            ref={warehouseCB}
                            onChange={loadGatepasses} />

                        <Textbox
                            ref={gatepassNameTB}
                            label="Gatepass Name"
                            tabIndex={1301}
                            onKeyDown={onGatepassNameTBKeydown}
                            style={{}} />

                        {/* <DateTimePicker
                            ref={dateTB}
                            label="Date"
                            time={false} /> */}

                        <Textarea
                            ref={remarksTB}
                            label="Remarks"
                            tabIndex={1302}
                            onKeyDown={onRemarksTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onGatepassSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onGatepassEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onGatepassDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onGatepassCancelBtnClick} />
                        </div>

                        <div className="dRow">
                            <Textbox
                                ref={searchGatepassTB}
                                label="Search"
                                style={{ flex: 'auto' }}
                                onChange={onGatepassSearchTBChange}
                                onKeyDown={onGatepassSearchTBKeydown}
                                tabIndex={1303} />

                            <DateTimePicker
                                ref={searchDateTB}
                                label="Date"
                                onChange={loadGatepasses}
                                style={{ width: '200px' }}
                                className="defaultMarginLeft"
                                time={false} />
                        </div>

                        <Table
                            ref={tableGRef}
                            columns={columnsG.current}
                            myTable='gatepassesTable'
                            isActive={isActive}
                            onEnterKeyDown={gatepassesTableOnclick}
                            onClick={gatepassesTableOnclick}
                            onDoubleClick={onGatepassEditBtnClick}
                            editBtn={{ visible: false, onClick: onGatepassEditBtnClick }}
                            deleteBtn={{ visible: false, onClick: () => { deleteGatepass() } }} />

                        <div className='dRow jce' style={{ marginBottom: '-18px' }}>
                            <ButtonIcon
                                label="Print"
                                icon="icons/buttons/print.png"
                                style={{ margin: '0px' }}
                                onClick={onPrintBtnClick} />
                        </div>

                        <div className="tableFooter">
                            <p><b>Total Gatepass: </b>{totalGatepasses}</p>
                        </div>

                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Gatepass Items</h4>
                        </div>

                        <ComboBox
                            ref={gatepassCB}
                            label="Gatepass"
                            data={gatepassData}
                            onChange={onGatepassCBChange} />

                        <AutocompleteTextbox
                            ref={gatepassItemTB}
                            label="Select Item"
                            tabIndex={1304}
                            onEnter={onGatepassItemTBKeydown}
                            style={{}} />

                        <div style={{ display: 'flex', width: '100%' }}>
                            <Textbox
                                ref={qtyTB}
                                label="Qty"
                                tabIndex={1305}
                                onKeyDown={onQtyTBKeydown}
                                style={{ flex: 1, marginRight: 'var(--defaultMargin)' }} />

                            <Textbox
                                ref={crtnTB}
                                label="Crtn"
                                tabIndex={1306}
                                onKeyDown={onCrtnTBKeydown}
                                style={{ flex: 1 }} />
                        </div>

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onGatepassItemSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onGatepassItemEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onGatepassItemDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onGatepassItemCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchGatepassItemsTB}
                            label="Search"
                            onChange={onGatepassItemSearchTBChange}
                            onKeyDown={onGatepassItemSearchTBKeydown}
                            tabIndex={1307} />

                        <Table
                            ref={tablePIRef}
                            columns={columnsGI.current}
                            myTable='gatepassItemsTable'
                            isActive={isActive}
                            onDoubleClick={onGatepassItemEditBtnClick}
                            editBtn={{ visible: false, onClick: onGatepassItemEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteGatepassItem() } }} />

                        <div className="tableFooter">
                            <p><b>Total Gatepass Items: </b>{totalGatepassItems}</p>
                        </div>

                    </div>

                    {(() => {
                        switch (activeBill) {
                            case "thermal":
                                return <div style={{ display: `none`, margin: '15px' }}>
                                    <GatepassThermal ref={billRef} data={billData} />
                                </div>
                            default:
                                return <div></div>;
                        }
                    })()}

                </div>
            </div>
        </>
    )
};

export default GatepassComponent;