/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/purchase/purchaseOrders.css';
import { mainReducerLoadPurchaseOrders } from 'state/reducers/mainReducer';
import { apiLoadPurchaseOrders as apiLoadMainPurchaseOrders } from "api/main/mainApi";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';

import {
    apiLoadPurchaseOrders,
    apiInsertPurchaseOrder,
    apiUpdatePurchaseOrder,
    apiDeletePurchaseOrder,
    apiGetPurchaseOrder,
    apiIsPurchaseOrderExists,
    apiGetOrder
} from "api/purchase/PurchaseOrdersApi";

import {
    apiLoadPurchaseOrderItems,
    apiInsertPurchaseOrderItem,
    apiUpdatePurchaseOrderItem,
    apiDeletePurchaseOrderItem,
    apiGetPurchaseOrderItem,
    apiIsPurchaseOrderItemExists
} from "api/purchase/PurchaseOrderItemsApi";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import AutocompleteTextbox from "components/tools/AutocompleteTextbox";
import ComboBox from 'components/tools/ComboBox';
import { convertToInt, convertToNumber, printSizes, selectNextElement } from 'utilities/Utils';
import { useReactToPrint } from 'react-to-print';
import OrderThermal from './bills/OrderThermal';
import OrderA4 from './bills/OrderA4';
import SearchItemsComponent from 'components/items/SearchItemsComponent';
/* #endregion Imports */

const PurchaseOrdersComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "purchaseOrdersForm";
    const isActive = useRef(false);
    const dispatch = useDispatch();
    const purchaseOrdersData = useSelector(state => state.main.purchaseOrders);
    const settings = useSelector(state => state.main.settings);
    const [activeComponent, setActiveComponent] = useState(null);
    const searchItemsFormRef = useRef(null);

    const purchaseOrderNameTB = useRef();
    const remarksTB = useRef();
    const searchPurchaseOrderTB = useRef();
    const printSizeCB = useRef();

    const purchaseOrderCB = useRef();
    const purchaseOrderItemTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const costTB = useRef();
    const totalTB = useRef();
    const searchPurchaseOrderItemsTB = useRef();
    const [stockPcsLB, setStockPcsLB] = useState("0");
    const [stockCrtnLB, setStockCrtnLB] = useState("0");


    const [activeBill, setActiveBill] = useState("thermal");
    const billRef = useRef();
    const [billData, setBillData] = useState([]);


    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tablePRef = useRef();
    const tablePIRef = useRef();
    const [totalPurchaseOrders, setTotalPurchaseOrders] = useState(0);
    const [totalPurchaseOrderItems, setTotalPurchaseOrderItems] = useState(0);

    // Other Variables
    const updateIdP = useRef(0);
    const isUpdateP = useRef(false);
    const updateIdPI = useRef(0);
    const isUpdatePI = useRef(false);
    const itemId = useRef(0);

    const formDataP = useRef(null);
    const formDataPI = useRef(null);

    const columnsP = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Order Name', row: "purchaseOrderName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '100px' } },
    ]);

    const columnsPIC = useRef([
        // { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '60px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '60px' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '90px' } },
    ]);

    const columnsPI = useRef([
        // { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '60px' } },
        // { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '60px' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '90px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    /* #region PurchaseOrders */
    const formLoad = () => {
        loadPurchaseOrders();

        apiLoadMainPurchaseOrders().then((result) => {
            dispatch(mainReducerLoadPurchaseOrders(result));
            purchaseOrderCB.current.setValue(0);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });

    }
    const loadPurchaseOrders = () => {
        if (tablePRef.current !== null)
            tablePRef.current.setLoading(true);

        apiLoadPurchaseOrders({
            text: searchPurchaseOrderTB.current.getText()
        }).then((result) => {
            try {
                if (isActive.current) {
                    setTotalPurchaseOrders(result.total.totalPurchaseOrders);
                    tablePRef.current.setData([...result.rows]);
                    tablePRef.current.setLoading(false);
                }
            } catch (error) {
                if (isActive.current) {
                    tablePRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tablePRef.current.setLoading(false);
            }
        });
    }
    const loadPurchaseOrdersCB = (result) => {
        dispatch(mainReducerLoadPurchaseOrders(result));
        purchaseOrderCB.current.setValue(0);
    }
    const inserted = useRef(true);
    const insertOrUpdatePurchaseOrder = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationP();

        if (isValid) {
            if (isUpdateP.current) {
                const data = getFormDataP();
                data.id = updateIdP.current;
                apiUpdatePurchaseOrder(data)
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            toast.current.show("Order updated successfully.", "s");
                            loadPurchaseOrders();
                            clearTextboxesP();
                            loadPurchaseOrdersCB(result);
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            } else {
                apiInsertPurchaseOrder(getFormDataP())
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            toast.current.show("Order saved successfully.", "s");
                            loadPurchaseOrders();
                            clearTextboxesP();
                            loadPurchaseOrdersCB(result);
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editPurchaseOrder = () => {
        const row = tablePRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetPurchaseOrder(tempId)
                .then((result) => {
                    if (isActive.current) {
                        updateIdP.current = tempId;
                        isUpdateP.current = true;

                        setFormDataP(result);
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        messageBox.current.show(err.message, "Error", "e");
                    }
                })
        }
    }
    const deletePurchaseOrder = () => {
        const row = tablePRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Order?")) {
                apiDeletePurchaseOrder(Number(row['id']))
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("Order deleted successfully.", "s");
                            loadPurchaseOrders();
                            loadPurchaseOrdersCB(result);
                            clearTextboxesP();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        };
    }
    const deletePurchaseOrderIsUpdate = () => {
        if (isUpdateP.current) {
            if (window.confirm("Are you sure to delete this Order?")) {
                apiDeletePurchaseOrder(updateIdP.current)
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("Order deleted successfully.", "s");
                            loadPurchaseOrders();
                            clearTextboxesP();
                            loadPurchaseOrdersCB(result);
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        }
    }
    const clearTextboxesP = () => {
        purchaseOrderNameTB.current.setText("");
        remarksTB.current.setText("");

        updateIdP.current = 0;
        isUpdateP.current = false;
        formDataP.current = null;

        purchaseOrderNameTB.current.focus();
    }
    const setFormDataP = (purchaseOrders) => {
        formDataP.current = purchaseOrders;

        purchaseOrderNameTB.current.setText(purchaseOrders.purchaseOrderName);
        remarksTB.current.setText(purchaseOrders.remarks);

        purchaseOrderNameTB.current.focus();
    }
    const getFormDataP = () => {
        const purchaseOrders = {
            purchaseOrderName: purchaseOrderNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText().trim(),
        }

        return purchaseOrders;
    }
    const validationP = async () => {
        const purchaseOrderName = purchaseOrderNameTB.current.getText().trim();
        const isValid = await isValidPurchaseOrder(purchaseOrderName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidPurchaseOrder = async (purchaseOrderName) => {
        if (purchaseOrderName === '') {
            toast.current.show("Please Enter Order Name.", "i");
            purchaseOrderNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateP.current) {
                if (purchaseOrderName.toLowerCase() === formDataP.current.purchaseOrderName.toLowerCase())
                    return true;
                else
                    return isPurchaseOrderExists(purchaseOrderName);
            }
            else
                return isPurchaseOrderExists(purchaseOrderName);
        }
    }
    const isPurchaseOrderExists = async (purchaseOrderName) => {
        const result = await apiIsPurchaseOrderExists(purchaseOrderName);
        if (result.isExists) {
            toast.current.show("Order Name already exists.", "i");
            purchaseOrderNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region PurchaseOrdersItems */
    const loadPurchaseOrderItems = () => {
        const purchaseOrderId = purchaseOrderCB.current.getValue();
        if (purchaseOrderId !== 0) {
            if (tablePIRef.current !== null)
                tablePIRef.current.setLoading(true);

            apiLoadPurchaseOrderItems({
                text: searchPurchaseOrderItemsTB.current.getText(),
                purchaseOrderId: purchaseOrderId,
            }).then((result) => {
                try {
                    if (isActive.current) {
                        if (result.rows.length > 0) {
                            setTotalPurchaseOrderItems(result.total.totalPurchaseOrderItems);
                            tablePIRef.current.setData([...result.rows]);
                            tablePIRef.current.setLoading(false);
                        } else {
                            tablePIRef.current.setLoading(false);
                            tablePIRef.current.clearData();
                        }
                    }
                } catch (error) {

                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                }
            });
        } else {
            tablePIRef.current.clearData();
        }
    }
    const inserted1 = useRef(true);
    const insertOrUpdatePurchaseOrderItem = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationPI();

        if (isValid) {
            if (isUpdatePI.current) {
                const data = getFormDataPI();
                data.id = updateIdPI.current;
                apiUpdatePurchaseOrderItem(data)
                    .then((result) => {
                        if (isActive.current) {
                            inserted1.current = true;
                            toast.current.show("PurchaseOrder Item updated successfully.", "s");
                            loadPurchaseOrderItems();
                            clearTextboxesPI();
                            loadPurchaseOrders();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted1.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            } else {
                apiInsertPurchaseOrderItem(getFormDataPI())
                    .then((result) => {
                        if (isActive.current) {
                            inserted1.current = true;
                            toast.current.show("PurchaseOrder Item saved successfully.", "s");
                            loadPurchaseOrderItems();
                            clearTextboxesPI();
                            loadPurchaseOrders();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted1.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editPurchaseOrderItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetPurchaseOrderItem(tempId)
                .then((result) => {
                    if (isActive.current) {
                        updateIdPI.current = tempId;
                        isUpdatePI.current = true;

                        setFormDataPI(result);
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        messageBox.current.show(err.message, "Error", "e");
                    }
                })
        }
    }
    const deletePurchaseOrderItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this PurchaseOrder Item?")) {
                apiDeletePurchaseOrderItem(Number(row['id']))
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("PurchaseOrder Item deleted successfully.", "s");
                            loadPurchaseOrderItems();
                            loadPurchaseOrders();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        };
    }
    const deletePurchaseOrderItemIsUpdate = () => {
        if (isUpdatePI.current) {
            if (window.confirm("Are you sure to delete this PurchaseOrder Item?")) {
                apiDeletePurchaseOrderItem(updateIdPI.current)
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("PurchaseOrder Item deleted successfully.", "s");
                            loadPurchaseOrderItems();
                            clearTextboxesPI();
                            loadPurchaseOrders();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        }
    }
    const clearTextboxesPI = () => {
        purchaseOrderItemTB.current.setText("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");
        costTB.current.setText("");
        totalTB.current.setText("");

        setStockPcsLB("0");
        setStockCrtnLB("0");

        updateIdPI.current = 0;
        isUpdatePI.current = false;
        itemId.current = 0;
        formDataPI.current = null;

        purchaseOrderItemTB.current.focus();
    }
    const setFormDataPI = (purchaseOrderItem) => {
        formDataPI.current = purchaseOrderItem;

        itemId.current = purchaseOrderItem.itemId;
        purchaseOrderItemTB.current.setText(purchaseOrderItem.itemname);
        qtyTB.current.setText(purchaseOrderItem.qty);
        crtnTB.current.setText(purchaseOrderItem.crtn);
        costTB.current.setText(purchaseOrderItem.cost);
        totalTB.current.setText(purchaseOrderItem.total);

        qtyTB.current.focus();
    }
    const getFormDataPI = () => {
        const purchaseOrderItem = {
            purchaseOrderId: purchaseOrderCB.current.getValue(),
            itemId: itemId.current,
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToInt(crtnTB.current.getText()),
            cost: convertToNumber(costTB.current.getText()),
            total: convertToNumber(totalTB.current.getText()),
        }

        return purchaseOrderItem;
    }
    const showDataIntoTextboxes = (item) => {
        formDataPI.current = item;

        itemId.current = Number(item.id);
        purchaseOrderItemTB.current.setText(item.itemname);
        costTB.current.setText(convertToNumber(item.lastCost) !== 0 ? item.lastCost : item.cost);

        setStockPcsLB(item.stockPcs);
        setStockCrtnLB(item.stockCrtn);

        qtyTB.current.focus();
    }
    const validationPI = async () => {
        const isValid = await isValidPurchaseOrderItem();
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidPurchaseOrderItem = async () => {
        if (purchaseOrderCB.current.getValue() === 0) {
            toast.current.show("Please Select Order.", "i");
            return false;
        }
        else if (itemId.current === 0) {
            toast.current.show("Please Select Order Item.", "i");
            purchaseOrderItemTB.current.focus();
            return false;
        }
        else if (convertToNumber(qtyTB.current.getText()) === 0 && convertToInt(crtnTB.current.getText()) === 0) {
            toast.current.show("Please Enter Quantity.", "i");
            qtyTB.current.focus();
            return false;
        }
        else if (convertToNumber(costTB.current.getText()) === 0) {
            toast.current.show("Please Enter Cost.", "i");
            costTB.current.focus();
            return false;
        }
        else {
            if (isUpdatePI.current) {
                if (itemId.current === formDataPI.current.itemId)
                    return true;
                else
                    return isPurchaseOrderItemExists();
            }
            else
                return isPurchaseOrderItemExists();
        }
    }
    const isPurchaseOrderItemExists = async () => {
        const result = await apiIsPurchaseOrderItemExists({
            itemId: itemId.current,
            purchaseOrderId: purchaseOrderCB.current.getValue()
        });
        if (result.isExists) {
            toast.current.show("Order Item already exists.", "i");
            purchaseOrderItemTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    const showSearchItemsForm = () => {
        props.setActiveComponent2('search');
    }
    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                const dataDiv = document.getElementById("purchaseOrderData");

                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    printBill(); // Recall untill populated completely
                    return;
                }

                // const ticketElement = document.getElementsByClassName("ticket")[0];
                // ticketElement.style.display = "block";

                // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(ticketElement, options);
                // await exporter.getPdf(options);

                // For Print to Printer
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setBillData([]);
            purchaseOrderNameTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    /* #region PurchaseOrders */
    const onPurchaseOrderSaveBtnClick = () => {
        insertOrUpdatePurchaseOrder();
    }

    const onPurchaseOrderEditBtnClick = () => {
        editPurchaseOrder();
    }

    const onPurchaseOrderDeleteBtnClick = () => {
        deletePurchaseOrderIsUpdate();
    }

    const onPurchaseOrderCancelBtnClick = () => {
        clearTextboxesP();
    }

    const purchaseOrdersTableOnclick = (row) => {
        const id = Number(row['id']);
        purchaseOrderCB.current.setValue(id);
    }
    const onPrintBtnClick = () => {
        const row = tablePRef.current.getSelectedRow();
        if (row && Number(row['total']) > 0) {
            const id = Number(row['id']);
            apiGetOrder(id).then((result) => {
                try {
                    setBillData([...result]);
                    printBill();
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    /* #endregion */

    /* #region PurchaseOrdersItems */
    const onPurchaseOrderItemSaveBtnClick = () => {
        insertOrUpdatePurchaseOrderItem();
    }

    const onPurchaseOrderItemEditBtnClick = () => {
        editPurchaseOrderItem();
    }

    const onPurchaseOrderItemDeleteBtnClick = () => {
        deletePurchaseOrderItemIsUpdate();
    }

    const onPurchaseOrderItemCancelBtnClick = () => {
        clearTextboxesPI();
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdatePurchaseOrder();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editPurchaseOrder();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deletePurchaseOrderIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesP();
        } else if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            onPrintBtnClick();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tablePRef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdatePurchaseOrderItem();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editPurchaseOrderItem();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deletePurchaseOrderItemIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesPI();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tablePIRef.current.focus();
        } else if (e.key.toLowerCase() === "f1") {
            e.preventDefault();
            showSearchItemsForm();
        }
    }
    // Search Textbox Keydown
    const onPurchaseOrderSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPurchaseOrders();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePRef.current.focus();
        }
    }
    const onPurchaseOrderItemSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPurchaseOrderItems();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    const onPurchaseOrderNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdatePurchaseOrder();
        }
    }
    const onPurchaseOrderItemTBKeydown = (data) => {
        showDataIntoTextboxes(data);
    }
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdatePurchaseOrderItem();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On PurchaseOrder Combobox Change
    const onPurchaseOrderCBChange = (selectedValue, selectedText) => {
        loadPurchaseOrderItems();
    }
    const onTextChange = () => {
        if (formDataPI.current != null) {
            const qty = convertToNumber(qtyTB.current.getText());
            const crtn = convertToInt(crtnTB.current.getText());
            const cost = convertToNumber(costTB.current.getText());

            const totalQty = qty + (crtn * formDataPI.current.crtnSize);
            const total = cost * totalQty;

            totalTB.current.setText(total);
        }
    }
    const onBillChange = () => {
        if (printSizeCB.current.getValue() === printSizes.thermal)
            setActiveBill("thermal");
        else if (printSizeCB.current.getValue() === printSizes.a4)
            setActiveBill("a4");

    }
    const onSearchItemSelected = (item) => {
        showDataIntoTextboxes(item);
    }
    const onPurchaseOrderSearchTBChange = () => {
        if(settings.instantSearch)
            loadPurchaseOrders();
    }
    const onPurchaseOrderItemSearchTBChange = () => {
        if(settings.instantSearch)
            loadPurchaseOrderItems();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
        props.setActiveComponent2(null);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
            onSearchItemSelected: showDataIntoTextboxes
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "search":
                            return <SearchItemsComponent
                                ref={searchItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onItemSelect={onSearchItemSelected}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>


            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

                            <div className='leftPanel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Orders</h4>
                                </div>
                                <Textbox
                                    ref={purchaseOrderNameTB}
                                    label="Order Name"
                                    tabIndex={1101}
                                    onKeyDown={onPurchaseOrderNameTBKeydown}
                                    style={{}} />

                                <Textarea
                                    ref={remarksTB}
                                    label="Remarks"
                                    tabIndex={1102}
                                    onKeyDown={onRemarksTBKeydown}
                                    style={{}} />

                                <div className="buttons">
                                    <ButtonIcon
                                        label="Save"
                                        icon="icons/buttons/save.png"
                                        onClick={onPurchaseOrderSaveBtnClick} />

                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onPurchaseOrderEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onPurchaseOrderDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Cancel"
                                        icon="icons/buttons/cancel.png"
                                        onClick={onPurchaseOrderCancelBtnClick} />
                                </div>

                                <Textbox
                                    ref={searchPurchaseOrderTB}
                                    label="Search"
                                    onChange={onPurchaseOrderSearchTBChange}
                                    onKeyDown={onPurchaseOrderSearchTBKeydown}
                                    tabIndex={1103} />

                                <Table
                                    ref={tablePRef}
                                    columns={columnsP.current}
                                    myTable='purchaseOrdersTable'
                                    isActive={isActive}
                                    onClick={purchaseOrdersTableOnclick}
                                    onDoubleClick={onPurchaseOrderEditBtnClick}
                                    editBtn={{ visible: false, onClick: onPurchaseOrderEditBtnClick }}
                                    deleteBtn={{ visible: true, onClick: () => { deletePurchaseOrder() } }} />

                                <div className='dRow jce' style={{ marginBottom: '-18px' }}>
                                    <ComboBox
                                        ref={printSizeCB}
                                        label="Size"
                                        style={{ width: '100px', marginRight: '8px', alignSelf: 'end' }}
                                        data={[{ id: 1, name: 'Thermal' }, { id: 2, name: 'A4' }]}
                                        onChange={onBillChange} />

                                    <ButtonIcon
                                        label="Print"
                                        icon="icons/buttons/print.png"
                                        style={{ margin: '0px' }}
                                        onClick={onPrintBtnClick} />
                                </div>

                                <div className="tableFooter" style={{ maxWidth: '200px' }}>
                                    <p><b>Total Orders: </b>{totalPurchaseOrders}</p>
                                </div>
                            </div>

                            <div className='centerPanel'>
                                <div className="centerLine"></div>
                            </div>

                            <div className='rightPanel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Order Items</h4>
                                </div>

                                <ComboBox
                                    ref={purchaseOrderCB}
                                    label="Orders"
                                    data={purchaseOrdersData}
                                    onChange={onPurchaseOrderCBChange} />

                                <AutocompleteTextbox
                                    ref={purchaseOrderItemTB}
                                    label="Select Item"
                                    tabIndex={1104}
                                    onEnter={onPurchaseOrderItemTBKeydown}
                                    style={{}} />

                                <div className='stockLabel dRow jcsa' style={{ width: '100%' }}>
                                    <p><b>Stock Pcs: </b>{stockPcsLB}</p>
                                    <p><b>Stock Crtn: </b>{stockCrtnLB}</p>
                                </div>

                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Textbox
                                        ref={qtyTB}
                                        label="Qty"
                                        tabIndex={1105}
                                        onKeyDown={onTextboxesKeyDown}
                                        onChange={onTextChange}
                                        style={{ flex: 1, marginRight: 'var(--defaultMargin)' }} />

                                    <Textbox
                                        ref={crtnTB}
                                        label="Crtn"
                                        tabIndex={1106}
                                        onChange={onTextChange}
                                        onKeyDown={onTextboxesKeyDown}
                                        style={{ flex: 1 }} />
                                </div>

                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Textbox
                                        ref={costTB}
                                        label="Cost"
                                        tabIndex={1107}
                                        onChange={onTextChange}
                                        onKeyDown={onLastTextboxKeyDown}
                                        style={{ flex: 1, marginRight: 'var(--defaultMargin)' }} />

                                    <Textbox
                                        ref={totalTB}
                                        label="Total"
                                        onKeyDown={onLastTextboxKeyDown}
                                        style={{ flex: 1 }} />
                                </div>

                                <div className="buttons">
                                    <ButtonIcon
                                        label="Save"
                                        icon="icons/buttons/save.png"
                                        onClick={onPurchaseOrderItemSaveBtnClick} />

                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onPurchaseOrderItemEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onPurchaseOrderItemDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Cancel"
                                        icon="icons/buttons/cancel.png"
                                        onClick={onPurchaseOrderItemCancelBtnClick} />
                                </div>

                                <Textbox
                                    ref={searchPurchaseOrderItemsTB}
                                    label="Search"
                                    onChange={onPurchaseOrderItemSearchTBChange}
                                    onKeyDown={onPurchaseOrderItemSearchTBKeydown}
                                    tabIndex={1108} />

                                <Table
                                    ref={tablePIRef}
                                    columns={settings.saleCartons ? columnsPIC.current : columnsPI.current}
                                    myTable='purchaseOrderItemsTable'
                                    isActive={isActive}
                                    onDoubleClick={onPurchaseOrderItemEditBtnClick}
                                    editBtn={{ visible: false, onClick: onPurchaseOrderItemEditBtnClick }}
                                    deleteBtn={{ visible: true, onClick: () => { deletePurchaseOrderItem() } }} />

                                <div className="tableFooter">
                                    <p><b>Total Items: </b>{totalPurchaseOrderItems}</p>
                                </div>

                            </div>

                        </div>

                        {(() => {
                            switch (activeBill) {
                                case "thermal":
                                    return <div style={{ display: `none`, margin: '15px' }}>
                                        <OrderThermal ref={billRef} data={billData} />
                                    </div>
                                case "a4":
                                    return <div style={{ display: `none`, margin: '15px' }}>
                                        <OrderA4 ref={billRef} data={billData} />
                                    </div>
                                default:
                                    return <div></div>;
                            }
                        })()}

                    </div>
                </div>
            </div>
        </>
    )
});

export default PurchaseOrdersComponent;