/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/sales/postedBills.css';
import AutocompleteTextbox from 'components/tools/AutocompleteTextbox';
import ComboBox from 'components/tools/ComboBox';
import DateTimePicker from 'components/tools/DateTimePicker';
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import UsersCB from 'components/tools/dropdowns/UsersCB';
import Pagination from 'components/tools/Pagination';
import ButtonIcon from 'components/tools/ButtonIcon';
import { apiDeleteSales, apiGetBill, apiLoadPostedBillItems, apiLoadPostedBills } from 'api/sales/SalesApi';
import { useReactToPrint } from "react-to-print";
import { getFromDate, getToDate } from 'utilities/Utils';
import { useSelector } from 'react-redux';
import dateFormat from "dateformat";
/* #endregion */

const PostedBillsComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "salesPostedBillsForm";
    const isActive = useRef(false);
    const passwords = useSelector(state => state.main.passwords);
    const settings = useSelector(state => state.main.settings);
    const onEdit = props.onEdit ? props.onEdit : () => { };

    const salesTypeBothRB = useRef();
    const salesTypeSalesRB = useRef();
    const salesTypeReturnRB = useRef();
    const salesOnBothRB = useRef();
    const salesOnCashRB = useRef();
    const salesOnCreditRB = useRef();
    const mopBothRB = useRef();
    const mopCashRB = useRef();
    const mopCreditRB = useRef();
    const typeBothRB = useRef();
    const typeRetailRB = useRef();
    const typeWholesaleRB = useRef();
    const itemId = useRef(0);
    const itemnameTB = useRef(0);
    const searchTB = useRef();
    const fromDateTB = useRef();
    const toDateTB = useRef();
    const userCB = useRef();
    const searchByCB = useRef();

    const tableBRef = useRef();
    const tableBIRef = useRef();
    const paginationRef = useRef();
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;

    const [totalBills, setTotalBills] = useState(0);
    const [totalBillItems, setTotalBillItems] = useState(0);

    const billRef = useRef();
    // eslint-disable-next-line
    const [billData, setBillData] = useState([]);

    const columnsB = useRef([
        { column: 'Inv No.', row: "invoiceNo", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Customer', row: "customerName", sortBy: "string", style: { minWidth: '300px', width: '100%' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '120px' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '140px' } },
        { column: 'On', row: "salesOn", sortBy: "string", style: { minWidth: '80px' } },
        { column: 'Type', row: "salesType", sortBy: "string", style: { minWidth: '80px' } },
        { column: 'MOP', row: "mop", sortBy: "string", style: { minWidth: '80px' } },
        { column: 'Type', row: "type", sortBy: "number", style: { minWidth: '60px' } },
        { column: 'User', row: "username", sortBy: "string", style: { minWidth: '150px' } },
    ]);


    const columnsBIC = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '241px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const columnsBI = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '387px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '98px' } },
    ]);

    const searchBy = useRef([
        { id: 0, name: 'By Name' },
        { id: 1, name: 'By Invoice No.' },
        { id: 2, name: 'By Ref. Invoice No.' },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadBills = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        tableBRef.current.setLoading(true);
        apiLoadPostedBills({
            page: page,
            itemsPerPage: itemsPerPage,
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
            salesOn: salesOnBothRB.current.checked ? 0 : salesOnCashRB.current.checked ? 1 : 2,
            salesType: salesTypeBothRB.current.checked ? 0 : salesTypeSalesRB.current.checked ? 1 : 2,
            isMopCashBank: mopBothRB.current.checked ? 2 : mopCashRB.current.checked ? 1 : 0,
            isRetail: typeBothRB.current.checked ? 2 : typeRetailRB.current.checked ? 1 : 0,
            itemId: itemId.current,
            userId: userCB.current.getValue(),
            searchBy: searchByCB.current.getValue(),
            text: searchTB.current.getText(),
        }).then((result) => {
            if (isActive.current) {
                if (result.rows.length > 0) {
                    setTotalBills(result.total.totalBills);
                    tableBRef.current.setData([...result.rows]);
                    tableBRef.current.setLoading(false);

                    const salesId = result.rows[0]['id'];
                    loadBillItems(salesId);
                } else {
                    tableBRef.current.clearData();
                    tableBIRef.current.clearData();
                    tableBRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableBRef.current.setLoading(false);
            }
        });
    }
    const loadBillByInvoice = (invoiceNo, date) => {
        tableBRef.current.setLoading(true);
        apiLoadPostedBills({
            page: 1,
            itemsPerPage: 25,
            fromDate: date,
            toDate: date,
            salesOn: salesOnBothRB.current.checked ? 0 : salesOnCashRB.current.checked ? 1 : 2,
            salesType: salesTypeBothRB.current.checked ? 0 : salesTypeSalesRB.current.checked ? 1 : 2,
            isMopCashBank: mopBothRB.current.checked ? 2 : mopCashRB.current.checked ? 1 : 0,
            isRetail: typeBothRB.current.checked ? 2 : typeRetailRB.current.checked ? 1 : 0,
            itemId: itemId.current,
            userId: userCB.current.getValue(),
            searchBy: 1,
            text: invoiceNo,
        }).then((result) => {
            if (isActive.current) {
                if (result.rows.length > 0) {
                    setTotalBills(result.total.totalBills);
                    tableBRef.current.setData([...result.rows]);
                    tableBRef.current.setLoading(false);

                    const salesId = result.rows[0]['id'];
                    loadBillItems(salesId);
                } else {
                    tableBRef.current.clearData();
                    tableBIRef.current.clearData();
                    tableBRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableBRef.current.setLoading(false);
            }
        });
    }
    const loadBillItems = (salesId) => {
        tableBIRef.current.setLoading(true);
        apiLoadPostedBillItems({ salesId: salesId })
            .then((result) => {
                if (isActive.current) {
                    if (result.rows.length > 0) {
                        setTotalBillItems(result.total.totalBillItems);
                        tableBIRef.current.setData([...result.rows]);
                        tableBIRef.current.setLoading(false);
                    } else {
                        tableBIRef.current.setLoading(false);
                    }
                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                    tableBIRef.current.setLoading(false);
                }
            });
    }
    const editData = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row !== null) {
            const date = row['date'].split(" ")[0];
            const today = dateFormat(new Date(), "dd/mm/yyyy");

            if (settings.sameDateBillEdit && (date === today)) {
                if (row['salesType'] === "Sales") {
                    const id = Number(row['id']);
                    const isRetail = Number(row['isRetail']);
                    onEdit(id, isRetail);
                    close();
                } else {
                    toast.current.show("You cannot edit return type bill", "i");
                }
            } else {
                if (passwords.useEditSalesBill) {
                    passwordBox.current.show("editSalesBill", (result) => {
                        if (result) {
                            if (row['salesType'] === "Sales") {
                                const id = Number(row['id']);
                                onEdit(id);
                                close();
                            } else {
                                toast.current.show("You cannot edit return type bill", "i");
                            }
                        }
                    });
                } else {
                    if (row['salesType'] === "Sales") {
                        const id = Number(row['id']);
                        onEdit(id);
                        close();
                    } else {
                        toast.current.show("You cannot edit return type bill", "i");
                    }
                }
            }

        }
    }
    const deleteData = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteSalesBill) {
                passwordBox.current.show("deleteSalesBill", (result) => {
                    if (result) {
                        apiDeleteSales({
                            id: Number(row['id']),
                            isPostedBill: true
                        }).then((result) => {
                            if (isActive.current) {
                                toast.current.show("Bill deleted successfully.", "s");
                                loadBills();
                            }
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Bill?")) {
                    apiDeleteSales({
                        id: Number(row['id']),
                        isPostedBill: true
                    }).then((result) => {
                        toast.current.show("Bill deleted successfully.", "s");
                        loadBills();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
                }
            }
        };
    }
    const exportBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const iFrame = printIframe.contentDocument;
            if (iFrame) {
                const dataDiv = iFrame.getElementsByClassName("dataDiv")[0];
                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    exportBill(); // Recall untill populated completely
                    return;
                }

                // const main = iFrame.getElementsByClassName("main")[0];
                // main.style.display = "block";

                // // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(main, options);
                // await exporter.getPdf(options);

                // // For Print to Printer
                // printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    const printBill = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row !== null) {
            apiGetBill({
                id: Number(row['id']),
                billType: 2,
                isPendingBill: false
            }).then((result) => {
                props.printBill(result);
                // setBillData([...result]);
                // if (isExport === false)
                //     printBill();
                // else
                //     exportBill();
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onBillTableClick = (row) => {
        const id = Number(row['id']);
        loadBillItems(id);
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteData();
    }
    const onPrintBtnClick = () => {
        printBill();
    }
    const onResetTimeBtnClick = () => {
        fromDateTB.current.setText(getFromDate());
        toDateTB.current.setText(getToDate());
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printBill();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            searchTB.current.focus();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    const onTableEnterKeyDown = () => {
        const row = tableBRef.current.getSelectedRow();
        const id = Number(row['id']);
        loadBillItems(id);
    }
    // Textboxes Keydown
    const onBarcodeTBKeyDown = (item) => {
        itemId.current = Number(item.id);
        itemnameTB.current.setText(item.itemname);
        searchTB.current.focus();

        loadBills();
    }
    // Search Textbox Keydown
    const onSearchTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadBills();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableBRef.current.focus();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onFiltersChange = () => {
        if (!props.showInReports)
            loadBills();
    }
    const onItemnameChange = () => {
        if (itemnameTB.current.getText() === '') {
            itemId.current = 0;
            loadBills();
        } else {
            itemId.current = 0;
        }
    }
    const onSearchTBChange = () => {
        if (settings.instantSearch)
            loadBills();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        if (props.invoiceNo !== undefined && props.invoiceNo !== 0) {
            loadBillByInvoice(props.invoiceNo, props.date);
        } else {
            formLoad();
        }
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        searchTB.current.focus();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        show();

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>

            <div id={formId} style={{ display: 'none' }} className='postedBillsForm'>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>
                        <div className='panel'>
                            <div className='topPanel'>
                                {/* Radio Group */}
                                <div className='topCB'>
                                    <div className="radioGroup">
                                        <div className="header">
                                            <p>Sales Type</p>
                                        </div>
                                        <div className="radioBody">
                                            <div className="myRadio">
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesTypeBothRB}
                                                        id='salesTypeBothRB'
                                                        className="form-check-input" type="radio" name="pSalesType" defaultChecked
                                                        onChange={onFiltersChange} />
                                                    <label htmlFor='salesTypeBothRB'>Both</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesTypeSalesRB}
                                                        id='salesTypeSalesRB'
                                                        className="form-check-input" type="radio" name="pSalesType" onChange={onFiltersChange} />
                                                    <label htmlFor='salesTypeSalesRB'>Sales</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesTypeReturnRB}
                                                        id='salesTypeReturnRB'
                                                        className="form-check-input" type="radio" name="pSalesType" onChange={onFiltersChange} />
                                                    <label htmlFor='salesTypeReturnRB'>Return</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="radioGroup defaultMarginLeft">
                                        <div className="header">
                                            <p>Sales On</p>
                                        </div>
                                        <div className="radioBody">
                                            <div className="myRadio">
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesOnBothRB}
                                                        id='salesOnBothRB'
                                                        className="form-check-input" type="radio" name="pSalesOn" defaultChecked
                                                        onChange={onFiltersChange} />
                                                    <label htmlFor='salesOnBothRB'>Both</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesOnCashRB}
                                                        id='salesOnCashRB'
                                                        className="form-check-input" type="radio" name="pSalesOn" onChange={onFiltersChange} />
                                                    <label htmlFor='salesOnCashRB'>Cash</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={salesOnCreditRB}
                                                        id='salesOnCreditRB'
                                                        className="form-check-input" type="radio" name="pSalesOn" onChange={onFiltersChange} />
                                                    <label htmlFor='salesOnCreditRB'>Credit</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="radioGroup defaultMarginLeft">
                                        <div className="header">
                                            <p>M.O.P</p>
                                        </div>
                                        <div className="radioBody">
                                            <div className="myRadio">
                                                <div className="radioItem">
                                                    <input
                                                        ref={mopBothRB}
                                                        id='mopBothRB'
                                                        className="form-check-input" type="radio" name="sMop" defaultChecked
                                                        onChange={onFiltersChange} />
                                                    <label htmlFor='mopBothRB'>Both</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={mopCashRB}
                                                        id='mopCashRB'
                                                        className="form-check-input" type="radio" name="sMop" onChange={onFiltersChange} />
                                                    <label htmlFor='mopCashRB'>Cash</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={mopCreditRB}
                                                        id='mopCreditRB'
                                                        className="form-check-input" type="radio" name="sMop" onChange={onFiltersChange} />
                                                    <label htmlFor='mopCreditRB'>Bank</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="radioGroup defaultMarginLeft">
                                        <div className="header">
                                            <p>Type</p>
                                        </div>
                                        <div className="radioBody">
                                            <div className="myRadio">
                                                <div className="radioItem">
                                                    <input
                                                        ref={typeBothRB}
                                                        id='typeBothRB'
                                                        className="form-check-input" type="radio" name="type" defaultChecked
                                                        onChange={onFiltersChange} />
                                                    <label htmlFor='typeBothRB'>Both</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={typeRetailRB}
                                                        id='typeRetailRB'
                                                        className="form-check-input" type="radio" name="type" onChange={onFiltersChange} />
                                                    <label htmlFor='typeRetailRB'>Retail</label>
                                                </div>
                                                <div className="radioItem">
                                                    <input
                                                        ref={typeWholesaleRB}
                                                        id='typeWholesaleRB'
                                                        className="form-check-input" type="radio" name="type" onChange={onFiltersChange} />
                                                    <label htmlFor='typeWholesaleRB'>Wholesale</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Search Group */}
                                <div className='' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className='searchGroup'>
                                        <AutocompleteTextbox
                                            ref={itemnameTB}
                                            label="Select Item"
                                            tabIndex={16}
                                            onChange={onItemnameChange}
                                            onEnter={onBarcodeTBKeyDown}
                                        />
                                        <UsersCB
                                            ref={userCB}
                                            onChange={onFiltersChange}
                                            className="defaultMarginLeft"
                                            style={{ maxWidth: '300px' }} />
                                        <ComboBox
                                            ref={searchByCB}
                                            label="Search By"
                                            className="defaultMarginLeft"
                                            style={{ maxWidth: '250px' }}
                                            data={searchBy.current}
                                            onChange={onFiltersChange}
                                        />
                                    </div>
                                    <div className='searchGroup'>
                                        <Textbox
                                            ref={searchTB}
                                            label="Search"
                                            onChange={onSearchTBChange}
                                            onKeyDown={onSearchTBKeyDown}
                                            style={{ flex: 2 }}
                                            tabIndex={17} />

                                        <DateTimePicker
                                            ref={fromDateTB}
                                            label="From Date"
                                            time={true}
                                            text={getFromDate()}
                                            onChange={onFiltersChange}
                                            className="defaultMarginLeft"
                                            style={{ flex: 1 }}
                                            tabIndex={100} />

                                        <DateTimePicker
                                            ref={toDateTB}
                                            label="To Date"
                                            time={true}
                                            text={getToDate()}
                                            onChange={onFiltersChange}
                                            className="defaultMarginLeft"
                                            style={{ flex: 1 }}
                                            tabIndex={100} />

                                        <div className='resetTimeBtn' onClick={onResetTimeBtnClick}>
                                            <img src="/icons/refresh.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                                {/* Table Layout */}
                                <Table
                                    ref={tableBRef}
                                    columns={columnsB.current}
                                    className="salesPostedBillsTable"
                                    isActive={isActive}
                                    autoSelectFirstRow={true}
                                    onEnterKeyDown={onTableEnterKeyDown}
                                    onClick={onBillTableClick}
                                    // onDoubleClick={onEditBtnClick}
                                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                                <div className="paginationFooter">
                                    <p><b>Total Bills: </b>{totalBills}</p>

                                    <Pagination
                                        ref={paginationRef}
                                        totalItems={totalBills}
                                        reload={loadBills}
                                        showTotalLabel={false}
                                        totalLabel="Total Bills: " />
                                </div>

                                <div className={`buttons ${props.invoiceNo === undefined ? "d-flex" : "d-none"}`}>
                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Print"
                                        icon="icons/buttons/print.png"
                                        onClick={onPrintBtnClick} />

                                </div>
                            </div>

                            <div className='bottomPanel'>
                                <Table
                                    ref={tableBIRef}
                                    columns={settings.saleCartons ? columnsBIC.current : columnsBI.current}
                                    className='salesPostedBillItemsTable'
                                    isActive={isActive}
                                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                                <div className="tableFooter">
                                    <p><b>Total Bill Items: </b>{totalBillItems}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default PostedBillsComponent;