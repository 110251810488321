import React, { forwardRef } from "react";
import 'style/reports/report.css';

const BarcodeItemnameLabel = forwardRef((props, ref) => {
    // const messageBox = props.messageBox;

    // const params = props.params;
    // const [data, setData] = useState(props.data);
    // const data = [1, 2, 3, 4];

    return (
        <>
            <div className="barcodeLabel" ref={ref}>
                <div>
                    <style>
                        {`@media print {
                                @page{
                                    width: 216px;
                                    height: auto;
                                    margin: 0px;
                                }
                            }
                        `}
                    </style>
                </div>

                {/* Page Header */}
                {/* {data &&
                    data.map(() => {
                        return <div className="barcodeLabel">
                            <h2>Itemname</h2>
                            <h4>12345</h4>
                        </div>
                    })
                } */}

                <div>
                    <h1>Print feature only available in offline</h1>
                </div>


            </div>
        </>
    )
});

export default BarcodeItemnameLabel;