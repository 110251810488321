/* #region Imports */
import React, { useRef, useEffect, useState } from "react";
import 'style/accounts/paymentsEntry.css';
import ComboBox from "components/tools/ComboBox";
import DateTimePicker from "components/tools/DateTimePicker";
import Table from "components/tools/Table";
import Textbox from "components/tools/Textbox";
import BankCBMOP from "components/tools/dropdowns/BankCBMOP";
import VendorCB from "components/tools/dropdowns/VendorCB";
import SubBankCBMOP from "components/tools/dropdowns/SubBankCBMOP";
import ButtonIcon from "components/tools/ButtonIcon";
import { apiDeleteEntry, apiGetBalance, apiLoadEntries, apiPassEntry } from "api/accounts/AccountsApi";
import { accounts, convertToNumber, entryTypes } from "utilities/Utils";
import Span from "components/tools/Span";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PaymentVoucher from "./vouchers/PaymentVoucher";
/* #endregion */

const PaymentsEntryComponent = (props) => {

    /* #region Variables */
    const formId = "paymentsEntryForm";
    const isActive = useRef(true);
    const settings = useSelector(state => state.main.settings);
    const userRights = useSelector(state => state.main.userRights);
    const passwords = useSelector(state => state.main.passwords);

    const vendorCB = useRef();
    const balanceLB = useRef(0);
    const balanceLabel = useRef("(P)");
    const amountTB = useRef();
    const dateTB = useRef();
    const mopCB = useRef();
    const bankCB = useRef();
    const subBankCB = useRef();
    const narationTB = useRef();
    const searchTB = useRef();
    const searchFromDateTB = useRef();
    const searchToDateTB = useRef();
    const searchMopCB = useRef();

    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalEntries, setTotalEntries] = useState(0);

    const billRef = useRef();
    const [billData, setBillData] = useState({});

    const columns = useRef([
        { column: 'id', row: "id", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Vendor', row: "accountName", sortBy: "string", style: { minWidth: '400px', width: '100%' } },
        { column: 'Amount', row: "amount", sortBy: "number", style: { minWidth: '120px' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '150px' } },
        { column: 'User', row: "username", sortBy: "string", style: { minWidth: '150px' } },
        { column: 'Naration', row: "naration", sortBy: "string", style: { minWidth: '400px' } },
    ]);
    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadEntries({
            entryType: entryTypes.payment,
            text: searchTB.current.getText(),
            fromDate: searchFromDateTB.current.getText(),
            toDate: searchToDateTB.current.getText(),
            mop: searchMopCB.current.getValue(),
        }).then((result) => {
            try {
                setTotalEntries(result.total.totalEntries);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                if (tableRef.current) {
                    tableRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (tableRef.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    const inserted = useRef(true);
    const passEntry = () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = validation();
        if (isValid) {
            apiPassEntry(getFormData())
                .then((result) => {
                    if (isActive.current) {
                        inserted.current = true;
                        toast.current.show("Entry Posted Successfully.", "s");
                        clearTextboxes();
                        loadData();
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    }
                });
        } else {
            inserted.current = true;
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteEntry(Number(row['id']))
                            .then((result) => {
                                if (isActive.current) {
                                    toast.current.show("Entry deleted successfully.", "s");
                                    loadData();
                                    clearTextboxes();
                                }
                            }).catch((err) => {
                                if (isActive.current) {
                                    messageBox.current.show(err.message, "Error", "e");
                                }
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Entry?")) {
                    apiDeleteEntry(Number(row['id']))
                        .then((result) => {
                            if (isActive.current) {
                                toast.current.show("Entry deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                            }
                        }).catch((err) => {
                            if (isActive.current) {
                                messageBox.current.show(err.message, "Error", "e");
                            }
                        });
                }
            }

        };
    }
    const clearTextboxes = () => {
        vendorCB.current.setValue(0);
        balanceLB.current.setText("0");
        balanceLabel.current.setText("(P)");

        amountTB.current.setText("");
        dateTB.current.setText(new Date());
        mopCB.current.setValue(0);
        bankCB.current.setValue(0);
        subBankCB.current.setValue(0);
        narationTB.current.setText("");

        amountTB.current.focus();
    }
    const getFormData = () => {
        const entry = {
            debitAccountId: vendorCB.current.getValue(),
            creditAccountId: mopCB.current.getValue() === 0 ? accounts.cash : subBankCB.current.getValue(),
            amount: convertToNumber(amountTB.current.getText()),
            naration: narationTB.current.getText(),
            date: dateTB.current.getText(),
            entryType: entryTypes.payment,
            isMopCashBank: mopCB.current.getValue() === 0 ? true : false,
        }

        return entry;
    }
    const validation = () => {
        if (vendorCB.current.getValue() === 0) {
            toast.current.show("Please Select Vendor", "i");
            return false;
        }

        if (convertToNumber(amountTB.current.getText()) === 0) {
            toast.current.show("Please Enter Amount", "i");
            return false;
        }

        if (mopCB.current.getValue() === 1) {
            if (bankCB.current.getValue() === 0) {
                toast.current.show("Please Select Bank", "i");
                return false;
            } else {
                if (subBankCB.current.getValue() === 0) {
                    toast.current.show("Please Select Bank Account", "i");
                    return false;
                }
            }
        }

        return true;
    }
    const changeNaration = () => {
        if (mopCB.current.getValue() === 0) {
            narationTB.current.setText(`Cash Paid to ${vendorCB.current.getText()}`);
        } else {
            narationTB.current.setText(`Cash Paid from ${subBankCB.current.getText()} to ${vendorCB.current.getText()}`);
        }
    }
    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                const dataDiv = document.getElementById("paymentVoucherData");

                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    printBill(); // Recall untill populated completely
                    return;
                }

                // const ticketElement = document.getElementsByClassName("ticket")[0];
                // ticketElement.style.display = "block";

                // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(ticketElement, options);
                // await exporter.getPdf(options);

                // For Print to Printer
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setBillData({});
            amountTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    const onPostBtnClick = () => {
        passEntry();
    }
    const onDeleteBtnClick = () => {
        deleteData();
    }
    const onPrintBtnClick = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            setBillData(row);
            printBill();
        };
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            passEntry();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onAmountTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            narationTB.current.focus();
        }
    }
    const onSearchTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onVendorCBChange = (e) => {
        const vendorId = vendorCB.current.getValue();
        if (vendorId !== 0) {
            apiGetBalance(vendorId)
                .then((result) => {
                    balanceLB.current.setText(Math.abs(result.balance));
                    balanceLabel.current.setText(result.label);
                    amountTB.current.focus();

                    changeNaration();
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        } else {
            balanceLB.current.setText("0");
            balanceLabel.current.setText("(P)");
        }
    }
    const mopCBOnChange = (e) => {
        if (mopCB.current.getValue() === 0) {
            bankCB.current.setValue(0);
            subBankCB.current.setValue(0);
            bankCB.current.disable();
            subBankCB.current.disable();
        } else {
            bankCB.current.enable();
            subBankCB.current.enable();
        }

        changeNaration();
    }
    const bankCBOnChange = (e) => {
        if (bankCB.current.getValue() !== 0) {
            subBankCB.current.loadData(bankCB.current.getValue());
        }
    }
    const subBankCBOnChange = (e) => {
        changeNaration();
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        // loadData();

        amountTB.current.focus();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.activeTab !== 'payments') {
            isActive.current = true;
        }

        // eslint-disable-next-line
    }, [props.activeTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                {/* Vendor */}
                <div className="balanceGroup">
                    <VendorCB
                        ref={vendorCB}
                        onChange={onVendorCBChange} />

                    <div className="balance">
                        <p><b>Balance: </b><Span ref={balanceLB} text="0" /> <Span ref={balanceLabel} text="(P)" /></p>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>

                    <Textbox
                        ref={amountTB}
                        label="Amount"
                        tabIndex={100}
                        style={{ flex: 1 }}
                        onKeyDown={onAmountTBKeyDown} />
                    <div className={`${userRights.dateWiseEntry ? "d-block" : "d-none"}`} style={{ flex: '1' }}>
                        <DateTimePicker
                            ref={dateTB}
                            label="Date"
                            time={false}
                            style={{ flex: 1 }}
                            className="defaultMarginLeft" />
                    </div>
                </div>

                {/* MOP Group */}
                <div className="mopGroup" style={{ marginTop: 'var(--defaultMargin)' }}>
                    <div style={{ display: 'flex', flexDirection: 'colums', flex: 1 }}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="mop">
                                <p>M.O.P</p>
                            </div>
                            <div className="mopCB">
                                <ComboBox
                                    ref={mopCB}
                                    data={[{ id: 0, name: 'Cash' }, { id: 1, name: 'Bank' }]}
                                    style={{ width: '100%' }}
                                    onChange={mopCBOnChange} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <BankCBMOP
                                ref={bankCB}
                                style={{ flex: 'auto' }}
                                className={"cb1"}
                                onChange={bankCBOnChange} />
                            <SubBankCBMOP
                                ref={subBankCB}
                                style={{ flex: 'auto' }}
                                className={"cb2"}
                                onChange={subBankCBOnChange} />
                        </div>
                    </div>
                </div>

                <Textbox
                    ref={narationTB}
                    label="Naraion"
                    tabIndex={101}
                />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 'var(--defaultMargin)' }}>
                    <ButtonIcon
                        label="Post"
                        icon="icons/buttons/save.png"
                        onClick={onPostBtnClick} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
                    <Textbox
                        ref={searchTB}
                        label="Search"
                        tabIndex={102}
                        onChange={onSearchTBChange}
                        onKeyDown={onSearchTBKeyDown}
                        style={{ flex: 2 }}
                    />

                    <DateTimePicker
                        ref={searchFromDateTB}
                        label="From Date"
                        time={false}
                        style={{ flex: 1 }}
                        className="defaultMarginLeft"
                        onChange={loadData} />

                    <DateTimePicker
                        ref={searchToDateTB}
                        label="To Date"
                        time={false}
                        style={{ flex: 1 }}
                        className="defaultMarginLeft"
                        onChange={loadData} />

                    <ComboBox
                        ref={searchMopCB}
                        data={[{ id: 0, name: 'Both' }, { id: 1, name: 'Cash' }, { id: 2, name: 'Bank' }]}
                        label="MOP"
                        className="defaultMarginLeft"
                        style={{ width: '100px' }}
                        onChange={loadData} />
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='paymentsEntryTable'
                    isActive={isActive}
                    onDoubleClick={() => { }}
                    onEnterKeyDown={(data) => { }}
                    editBtn={{ visible: false, onClick: () => { } }}
                    deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                <div className="bottom">
                    <div className="tableFooter">
                        <p><b>Total Entries: </b>{totalEntries}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        <ButtonIcon
                            label="Print"
                            icon="icons/buttons/print.png"
                            style={{ marginLeft: 'var(--defaultMargin)' }}
                            onClick={onPrintBtnClick} />
                    </div>
                </div>
            </div>

            <div style={{ display: "none" }}>
                <PaymentVoucher ref={billRef} data={billData} />
            </div>

        </>
    )
}

export default PaymentsEntryComponent;