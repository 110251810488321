/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/items/packages.css';
import { mainReducerLoadPackages } from 'state/reducers/mainReducer';
import { apiLoadPackages as apiLoadMainPackages } from "api/main/mainApi";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';

import {
    apiLoadPackages,
    apiInsertPackage,
    apiUpdatePackage,
    apiDeletePackage,
    apiGetPackage,
    apiIsPackageExists
} from "api/items/PackagesApi";

import {
    apiLoadPackageItems,
    apiInsertPackageItem,
    apiUpdatePackageItem,
    apiDeletePackageItem,
    apiGetPackageItem,
    apiIsPackageItemExists
} from "api/items/PackageItemsApi";
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import AutocompleteTextbox from "components/tools/AutocompleteTextbox";
import ComboBox from 'components/tools/ComboBox';
import { convertToNumber } from 'utilities/Utils';
/* #endregion Imports */

const PackagesComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "packagesForm";
    const isActive = useRef(false);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.main.settings);
    const packagesData = useSelector(state => state.main.packages);

    const packageNameTB = useRef();
    const remarksTB = useRef();
    const searchPackageTB = useRef();

    const packageCB = useRef();
    const packageItemTB = useRef();
    const qtyTB = useRef();
    const rateTB = useRef();
    const searchPackageItemsTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tablePRef = useRef();
    const tablePIRef = useRef();
    const [totalPackages, setTotalPackages] = useState(0);
    const [totalPackageItems, setTotalPackageItems] = useState(0);

    // Other Variables
    const updateIdP = useRef(0);
    const isUpdateP = useRef(false);
    const updateIdPI = useRef(0);
    const isUpdatePI = useRef(false);
    const itemId = useRef(0);

    const formDataP = useRef(null);
    const formDataPI = useRef(null);

    const columnsP = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Package Name', row: "packageName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '100px' } },
    ]);

    const columnsPI = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Package Item', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    /* #region Packages */
    const formLoad = () => {
        loadPackages();

        apiLoadMainPackages().then((result) => {
            dispatch(mainReducerLoadPackages(result));
            packageCB.current.setValue(0);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadPackages = () => {
        if (tablePRef.current !== null)
            tablePRef.current.setLoading(true);

        apiLoadPackages({
            text: searchPackageTB.current.getText()
        }).then((result) => {
            try {
                setTotalPackages(result.total.totalPackages);
                tablePRef.current.setData([...result.rows]);
                tablePRef.current.setLoading(false);
            } catch (error) {
                tablePRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadPackagesCB = (result) => {
        dispatch(mainReducerLoadPackages(result));
        packageCB.current.setValue(0);
    }
    const inserted = useRef(true);
    const insertOrUpdatePackage = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationP();

        if (isValid) {
            if (isUpdateP.current) {
                const data = getFormDataP();
                data.id = updateIdP.current;
                apiUpdatePackage(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Package updated successfully.", "s");
                        loadPackages();
                        clearTextboxesP();
                        loadPackagesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertPackage(getFormDataP())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Package saved successfully.", "s");
                        loadPackages();
                        clearTextboxesP();
                        loadPackagesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editPackage = () => {
        const row = tablePRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetPackage(tempId)
                .then((result) => {
                    updateIdP.current = tempId;
                    isUpdateP.current = true;

                    setFormDataP(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deletePackage = () => {
        const row = tablePRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Package?")) {
                apiDeletePackage(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Package deleted successfully.", "s");
                        loadPackages();
                        loadPackagesCB(result);
                        clearTextboxesP();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deletePackageIsUpdate = () => {
        if (isUpdateP.current) {
            if (window.confirm("Are you sure to delete this Package?")) {
                apiDeletePackage(updateIdP.current)
                    .then((result) => {
                        toast.current.show("Package deleted successfully.", "s");
                        loadPackages();
                        clearTextboxesP();
                        loadPackagesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesP = () => {
        packageNameTB.current.setText("");
        remarksTB.current.setText("");

        updateIdP.current = 0;
        isUpdateP.current = false;
        formDataP.current = null;

        packageNameTB.current.focus();
    }
    const setFormDataP = (packages) => {
        formDataP.current = packages;

        packageNameTB.current.setText(packages.packageName);
        remarksTB.current.setText(packages.remarks);

        packageNameTB.current.focus();
    }
    const getFormDataP = () => {
        const packages = {
            packageName: packageNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText().trim(),
        }

        return packages;
    }
    const validationP = async () => {
        const packageName = packageNameTB.current.getText().trim();
        const isValid = await isValidPackage(packageName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidPackage = async (packageName) => {
        if (packageName === '') {
            toast.current.show("Please Enter Package Name.", "i");
            packageNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateP.current) {
                if (packageName.toLowerCase() === formDataP.current.packageName.toLowerCase())
                    return true;
                else
                    return isPackageExists(packageName);
            }
            else
                return isPackageExists(packageName);
        }
    }
    const isPackageExists = async (packageName) => {
        const result = await apiIsPackageExists(packageName);
        if (result.isExists) {
            toast.current.show("Package Name already exists.", "i");
            packageNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region PackagesItems */
    const loadPackageItems = () => {
        const packageId = packageCB.current.getValue();
        if (packageId !== 0) {
            if (tablePIRef.current !== null)
                tablePIRef.current.setLoading(true);

            apiLoadPackageItems({
                text: searchPackageItemsTB.current.getText(),
                packageId: packageId,
            }).then((result) => {
                try {
                    setTotalPackageItems(result.total.totalPackageItems);
                    tablePIRef.current.setData([...result.rows]);
                    tablePIRef.current.setLoading(false);
                } catch (error) {
                    tablePIRef.current.setLoading(false);
                }
            }).catch((err) => {
                tablePIRef.current.setLoading(false);
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            tablePIRef.current.clearData();
        }
    }
    const inserted1 = useRef(true);
    const insertOrUpdatePackageItem = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationPI();

        if (isValid) {
            if (isUpdatePI.current) {
                const data = getFormDataPI();
                data.id = updateIdPI.current;
                apiUpdatePackageItem(data)
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Package Item updated successfully.", "s");
                        loadPackageItems();
                        clearTextboxesPI();
                        loadPackages();
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertPackageItem(getFormDataPI())
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Package Item saved successfully.", "s");
                        loadPackageItems();
                        clearTextboxesPI();
                        loadPackages();
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editPackageItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetPackageItem(tempId)
                .then((result) => {
                    updateIdPI.current = tempId;
                    isUpdatePI.current = true;

                    setFormDataPI(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deletePackageItem = () => {
        const row = tablePIRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Package Item?")) {
                apiDeletePackageItem(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Package Item deleted successfully.", "s");
                        loadPackageItems();
                        loadPackages();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deletePackageItemIsUpdate = () => {
        if (isUpdatePI.current) {
            if (window.confirm("Are you sure to delete this Package Item?")) {
                apiDeletePackageItem(updateIdPI.current)
                    .then((result) => {
                        toast.current.show("Package Item deleted successfully.", "s");
                        loadPackageItems();
                        clearTextboxesPI();
                        loadPackages();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesPI = () => {
        packageItemTB.current.setText("");
        qtyTB.current.setText("");
        rateTB.current.setText("");

        updateIdPI.current = 0;
        isUpdatePI.current = false;
        itemId.current = 0;
        formDataPI.current = null;

        packageItemTB.current.focus();
    }
    const setFormDataPI = (packageItem) => {
        formDataPI.current = packageItem;

        itemId.current = packageItem.itemId;
        packageItemTB.current.setText(packageItem.itemname);
        qtyTB.current.setText(packageItem.qty);
        rateTB.current.setText(packageItem.rate);

        qtyTB.current.focus();
    }
    const getFormDataPI = () => {
        const packageItem = {
            packageId: packageCB.current.getValue(),
            itemId: itemId.current,
            qty: convertToNumber(qtyTB.current.getText()),
            rate: convertToNumber(rateTB.current.getText()),
        }

        return packageItem;
    }
    const showDataIntoTextboxes = (item) => {
        itemId.current = Number(item.id);
        packageItemTB.current.setText(item.itemname);
        rateTB.current.setText(item.retail);
        qtyTB.current.focus();
    }
    const validationPI = async () => {
        // const packageItemName = packageItemTB.current.getText().trim();
        const isValid = await isValidPackageItem();
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidPackageItem = async () => {
        if (packageCB.current.getValue() === 0) {
            toast.current.show("Please Select Package.", "i");
            return false;
        }
        else if (itemId.current === 0) {
            toast.current.show("Please Select Package Item.", "i");
            packageItemTB.current.focus();
            return false;
        }
        else if (convertToNumber(qtyTB.current.getText()) === 0) {
            toast.current.show("Please Enter Quantity.", "i");
            qtyTB.current.focus();
            return false;
        }
        else if (convertToNumber(rateTB.current.getText()) === 0) {
            toast.current.show("Please Enter Rate.", "i");
            rateTB.current.focus();
            return false;
        }
        else {
            if (isUpdatePI.current) {
                if (itemId.current === formDataPI.current.itemId)
                    return true;
                else
                    return isPackageItemExists();
            }
            else
                return isPackageItemExists();
        }
    }
    const isPackageItemExists = async () => {
        const result = await apiIsPackageItemExists({
            itemId: itemId.current,
            packageId: packageCB.current.getValue()
        });
        if (result.isExists) {
            toast.current.show("Package Item already exists.", "i");
            packageItemTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */
    /* #endregion */

    /* #region Clicks */
    /* #region Packages */
    const onPackageSaveBtnClick = () => {
        insertOrUpdatePackage();
    }

    const onPackageEditBtnClick = () => {
        editPackage();
    }

    const onPackageDeleteBtnClick = () => {
        deletePackageIsUpdate();
    }

    const onPackageCancelBtnClick = () => {
        clearTextboxesP();
    }

    const packagesTableOnclick = (row) => {
        const id = Number(row['id']);
        packageCB.current.setValue(id);
    }
    /* #endregion */

    /* #region PackagesItems */
    const onPackageItemSaveBtnClick = () => {
        insertOrUpdatePackageItem();
    }

    const onPackageItemEditBtnClick = () => {
        editPackageItem();
    }

    const onPackageItemDeleteBtnClick = () => {
        deletePackageItemIsUpdate();
    }

    const onPackageItemCancelBtnClick = () => {
        clearTextboxesPI();
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (props.mainActiveTab !== "items")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdatePackage();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editPackage();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deletePackageIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesP();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tablePRef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdatePackageItem();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editPackageItem();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deletePackageItemIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesPI();
        } else if (e.altKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onPackageSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPackages();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePRef.current.focus();
        }
    }
    const onPackageItemSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPackageItems();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    const onPackageNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdatePackage();
        }
    }
    const onPackageItemTBKeydown = (data) => {
        showDataIntoTextboxes(data);
    }
    const onQtyTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            rateTB.current.focus();
        }
    }
    const onRateTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdatePackageItem();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Package Combobox Change
    const onPackageCBChange = (selectedValue, selectedText) => {
        loadPackageItems();
    }
    const onPackageSearchTBChange = () => {
        if(settings.instantSearch)
            loadPackages();
    }
    const onPackageItemSearchTBChange = () => {
        if(settings.instantSearch)
            loadPackageItems();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

                            <div className='panel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Packages</h4>
                                </div>
                                <Textbox
                                    ref={packageNameTB}
                                    label="Package Name"
                                    tabIndex={25}
                                    onKeyDown={onPackageNameTBKeydown}
                                    style={{}} />

                                <Textarea
                                    ref={remarksTB}
                                    label="Remarks"
                                    tabIndex={26}
                                    onKeyDown={onRemarksTBKeydown}
                                    style={{}} />

                                <div className="buttons">
                                    <ButtonIcon
                                        label="Save"
                                        icon="icons/buttons/save.png"
                                        onClick={onPackageSaveBtnClick} />

                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onPackageEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onPackageDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Cancel"
                                        icon="icons/buttons/cancel.png"
                                        onClick={onPackageCancelBtnClick} />
                                </div>

                                <Textbox
                                    ref={searchPackageTB}
                                    label="Search"
                                    onChange={onPackageSearchTBChange}
                                    onKeyDown={onPackageSearchTBKeydown}
                                    tabIndex={27} />

                                <Table
                                    ref={tablePRef}
                                    columns={columnsP.current}
                                    myTable='packagesTable'
                                    isActive={isActive}
                                    onEnterKeyDown={packagesTableOnclick}
                                    onClick={packagesTableOnclick}
                                    onDoubleClick={onPackageEditBtnClick}
                                    editBtn={{ visible: false, onClick: onPackageEditBtnClick }}
                                    deleteBtn={{ visible: true, onClick: () => { deletePackage() } }} />

                                <div className="tableFooter">
                                    <p><b>Total Packages: </b>{totalPackages}</p>
                                </div>

                            </div>

                            <div className='centerPanel'>
                                <div className="centerLine"></div>
                            </div>

                            <div className='panel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Package Items</h4>
                                </div>

                                <ComboBox
                                    ref={packageCB}
                                    label="Packages"
                                    data={packagesData}
                                    onChange={onPackageCBChange} />

                                <AutocompleteTextbox
                                    ref={packageItemTB}
                                    label="Select Item"
                                    tabIndex={28}
                                    onEnter={onPackageItemTBKeydown}
                                    style={{}} />

                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Textbox
                                        ref={qtyTB}
                                        label="Quantity"
                                        tabIndex={29}
                                        onKeyDown={onQtyTBKeydown}
                                        style={{ flex: 1, marginRight: 'var(--defaultMargin)' }} />

                                    <Textbox
                                        ref={rateTB}
                                        label="Rate"
                                        tabIndex={30}
                                        onKeyDown={onRateTBKeydown}
                                        style={{ flex: 1 }} />
                                </div>

                                <div className="buttons">
                                    <ButtonIcon
                                        label="Save"
                                        icon="icons/buttons/save.png"
                                        onClick={onPackageItemSaveBtnClick} />

                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onPackageItemEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onPackageItemDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Cancel"
                                        icon="icons/buttons/cancel.png"
                                        onClick={onPackageItemCancelBtnClick} />
                                </div>

                                <Textbox
                                    ref={searchPackageItemsTB}
                                    label="Search"
                                    onChange={onPackageItemSearchTBChange}
                                    onKeyDown={onPackageItemSearchTBKeydown}
                                    tabIndex={31} />

                                <Table
                                    ref={tablePIRef}
                                    columns={columnsPI.current}
                                    myTable='packageItemsTable'
                                    isActive={isActive}
                                    onDoubleClick={onPackageItemEditBtnClick}
                                    editBtn={{ visible: false, onClick: onPackageItemEditBtnClick }}
                                    deleteBtn={{ visible: true, onClick: () => { deletePackageItem() } }} />

                                <div className="tableFooter">
                                    <p><b>Total Packages Items: </b>{totalPackageItems}</p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default PackagesComponent;