/* #region Imports */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import 'style/items/searchItems.css';
import {
    apiLoadItems,
} from "api/items/ItemsApi";
import 'style/items/items.css';
import Table from 'components/tools/Table';
import ComboBox from 'components/tools/ComboBox';
import Textbox from 'components/tools/Textbox';
import Pagination from "components/tools/Pagination";
import VendorCB from "components/tools/dropdowns/VendorCB";
import CategoryCB from "components/tools/dropdowns/CategoryCB";
import SubCategoryCB from "components/tools/dropdowns/SubCategoryCB";
import { useSelector } from "react-redux";
/* #endregion Imports */

const SearchItemsComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "searchItemsForm";
    const isActive = useRef(false);
    const settings = useSelector(state => state.main.settings);
    const onItemSelect = props.onItemSelect ? props.onItemSelect : () => { };

    // Search References
    const searchCategoryCB = useRef();
    const searchSubCategoryCB = useRef();
    const searchVendorCB = useRef();
    const searchTB = useRef();
    const searchByCB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const tableRef = useRef();
    const paginationRef = useRef();
    const [totalItems, setTotalItems] = useState(0);


    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Qty', row: "stockPcs", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        // { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '150px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const columnsC = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Qty', row: "stockPcs", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Crtn', row: "stockCrtn", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        // { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '150px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const columnsF = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate3', row: "rate3", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate4', row: "rate4", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Qty', row: "stockPcs", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Crtn', row: "stockCrtn", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '150px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const searchBy = useRef([
        { id: 0, name: 'By Name' },
        { id: 1, name: 'By Barcode' },
        { id: 2, name: 'By Ref. Code' },
        { id: 3, name: 'By Urduname' },
        { id: 4, name: 'By Cost' },
        { id: 5, name: 'By Retail' },
        { id: 6, name: 'By Wholesale' },
        { id: 7, name: 'By Carton Rate' },
        { id: 8, name: 'By Carton Size' },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadData = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        tableRef.current.setLoading(true);
        apiLoadItems({
            page: page,
            itemsPerPage: itemsPerPage,
            categoryId: searchCategoryCB.current.getValue(),
            subCategoryId: searchSubCategoryCB.current.getValue(),
            vendorId: searchVendorCB.current.getValue(),
            searchBy: searchByCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                if (isActive.current) {
                    setTotalItems(result.total.totalItems);
                    tableRef.current.setData([...result.rows]);
                    tableRef.current.setLoading(false);
                }
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            searchTB.current.focus();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onDoubleClick = (item) => {
        onItemSelect(item);
        close();
    }
    const onEnterKeyDown = (item) => {
        onItemSelect(item);
        close();
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSearchCategoryCBChange = (selectedValue, selectedText) => {
        searchSubCategoryCB.current.loadData(selectedValue);
        loadData();
    }
    // On Sub-Category Combobox Change
    const onSearchSubCategoryCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    // On Vendor Combobox Change
    const onSearchVendorCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    // On Search By Combobox Change
    const onSearchByCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        if (props.setActiveComponent2) {
            isActive.current = false;

            props.setActiveComponent2(null);
        }
        else {
            props.isActive.current = true;
            isActive.current = false;

            props.setActiveComponent(null);
        }
    }

    const formLoad = () => {
        searchTB.current.focus();
        loadData();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'block' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

                            <div className="searchContainer1">
                                <CategoryCB
                                    ref={searchCategoryCB}
                                    onChange={onSearchCategoryCBChange} />

                                <SubCategoryCB
                                    ref={searchSubCategoryCB}
                                    onChange={onSearchSubCategoryCBChange}
                                    className="defaultMarginLeft" />

                                <VendorCB
                                    ref={searchVendorCB}
                                    onChange={onSearchVendorCBChange}
                                    className="defaultMarginLeft" />
                            </div>

                            <div className="searchContainer2" style={{ display: 'flex' }}>

                                <Textbox
                                    ref={searchTB}
                                    label="Search"
                                    onKeyDown={onSearchTBKeydown}
                                    onChange={onSearchTBChange}
                                    style={{ flex: 2 }}
                                    tabIndex={19} />

                                <ComboBox
                                    ref={searchByCB}
                                    label="Search By"
                                    className="searchBy defaultMarginLeft"
                                    data={searchBy.current}
                                    onChange={onSearchByCBChange}
                                    style={{ flex: 1 }} />

                            </div>

                            <Table
                                ref={tableRef}
                                columns={settings.fourRateSystem ? columnsF.current : settings.saleCartons ? columnsC.current : columns.current}
                                myTable='searchItemsTable'
                                className="border"
                                isActive={isActive}
                                // autoSelectFirstRow={true}
                                onDoubleClick={onDoubleClick}
                                onEnterKeyDown={onEnterKeyDown}
                                editBtn={{ visible: false, onClick: () => { } }}
                                deleteBtn={{ visible: true, onClick: () => { } }} />

                            <div className="paginationFooter">
                                <p><b>Total Items: </b>{totalItems}</p>
                                <Pagination
                                    ref={paginationRef}
                                    totalItems={totalItems}
                                    reload={loadData}
                                    showTotalLabel={false}
                                    totalLabel="Total Items: " />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default SearchItemsComponent;