/* #region Imports */
import React, { useRef, useEffect, useState } from "react";
import 'style/main/dashboard.css';
import { useSelector } from "react-redux";
import Icons from 'components/Icons';
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import BranchGroupsCB from "components/tools/dropdowns/BranchGroupsCB";
import { getBranchGroupId, getBranchId, setBranchGroupId, setBranchId } from "utilities/DB";
import UserComponent from "./UserComponent";
import ComboBox from "components/tools/ComboBox";
import { changeTheme, getTheme } from "utilities/Utils";
import { useReactToPrint } from "react-to-print";
import CheckBillComponent from "components/sales/CheckBillComponent";
import ThinButton from "components/tools/ThinButton";
/* #endregion */

const DashboardComponent = (props) => {

    /* #region Variables */
    const formId = "dashboardForm";
    const isActive = useRef(true);
    const user = useSelector(state => state.main.user);
    const branchCB = useRef();
    const branchGroupCB = useRef();
    const [activeComponent, setActiveComponent] = useState(null);
    const themeCB = useRef();

    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const themes = useRef([
        { id: 1, name: 'Default' },
        { id: 2, name: 'Red' },
        { id: 3, name: 'Green' },
        { id: 4, name: 'Black' },
    ]);


    const billRef = useRef();
    /* #endregion */

    const onCheckBillBtnClick = ()=>{
        setActiveComponent("checkBill");
    }

    const isFirstTimeBranch = useRef(true);
    const onBranchChange = () => {
        if (isFirstTimeBranch.current) {
            isFirstTimeBranch.current = false;
            return;
        }

        setBranchId(branchCB.current.getValue());
        props.onBranchChange();
    }
    const isFirstTimeBranchGroup = useRef(true);
    const onBranchGroupChange = () => {
        if (isFirstTimeBranchGroup.current) {
            isFirstTimeBranchGroup.current = false;
            return;
        }

        setBranchGroupId(branchGroupCB.current.getValue());
        props.onBranchChange();
    }
    const showUserForm = () => {
        setActiveComponent('user');
    }
    // const getRootProperty = (variable)=>{
    //     const root = getRootElement();
    //     return root.style.getProperty(variable);
    // }
    const onThemeChange = () => {
        changeTheme(themeCB.current.getValue());
    }
    useEffect(() => {
        branchCB.current.setValue(getBranchId());
        branchGroupCB.current.setValue(getBranchGroupId());

        themeCB.current.setValue(getTheme());

        // eslint-disable-next-line
    }, []);

    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {

        },
        onPrintError: (error) => alert(error),
    });

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "user":
                            return <UserComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        case "checkBill":
                            return <CheckBillComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        default:
                            return <div></div>;
                    }
                })()}

            </div>

            <div id={formId}>
                <div className="backgroundContainer">
                    <div className="foregroundContainer">
                        <div className="main">
                            <div className="dCol topPanel">
                                <p className="welcome" onClick={showUserForm}>Welcome {user.username}</p>
                                <p className="business">Let's Start Today's Business.</p>

                                <div className="d-none">
                                    <div ref={billRef} className="myBox">
                                        <p className="urdu">میرا نام ہے۔</p>
                                        <p className="myFont">(1234)</p>
                                    </div>

                                    <button onClick={printBill}>Print</button>
                                </div>
                            </div>

                            <div className="bottomPanel">
                                <div className="dCol">
                                    <div className={`${user.isMainUser ? "d-block" : 'd-none'}`} style={{ width: '400px' }}>
                                        <BranchesCB
                                            ref={branchCB}
                                            title="Branch"
                                            onChange={onBranchChange} />
                                        <BranchGroupsCB
                                            ref={branchGroupCB}
                                            title="Branch Group"
                                            onChange={onBranchGroupChange} />
                                    </div>

                                    <ComboBox
                                        ref={themeCB}
                                        label="Theme"
                                        data={themes.current}
                                        onChange={onThemeChange} />
                                </div>

                                <div className="bottomRight">
                                    <ThinButton
                                        label="Check Bill"
                                        onClick={onCheckBillBtnClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="bgImage" src={Icons.dashboard} alt="Not Found" />
                </div>
            </div>
        </>
    )
};

export default DashboardComponent;