/* #region Imports */
import { apiClearBarcodeLabel, apiDeleteBarcodeLabel, apiGetBarcodeLabel, apiInsertBarcodeLabel, apiLoadBarcodeLabels, apiUpdateBarcodeLabel } from 'api/utilities/BarcodeLabelsApi';
import SearchItemsComponent from 'components/items/SearchItemsComponent';
import AutocompleteTextbox from 'components/tools/AutocompleteTextbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Checkbox from 'components/tools/Checkbox';
import DateTimePicker from 'components/tools/DateTimePicker';
import MiniButton from 'components/tools/MiniButton';
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import { evaluate } from 'mathjs';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import 'style/utilities/barcodeLabels.css';
import { addDays, convertToInt, convertToNumber, isExpression, selectNextElement } from 'utilities/Utils';
import BarcodeItemnameLabel from './labels/BarcodeItemname';
/* #endregion Imports */

const BarcodeLabelsComponent = (props) => {

    /* #region Variables */
    const formId = "barcodeLabelsForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const searchItemsFormRef = useRef(null);
    const [activeComponent, setActiveComponent] = useState(null);
    // eslint-disable-next-line
    const [activeReport, setActiveReport] = useState("barcodeitemname");
    const settings = useSelector(state => state.main.settings);

    const barcodeItemnameTB = useRef();
    const itemId = useRef(0);
    const qtyTB = useRef();
    const itemnameTB = useRef();
    const barcodeTB = useRef();
    const rateTB = useRef();
    const crtnRateTB = useRef();
    const marketPriceTB = useRef();
    const expiryTB = useRef();

    const autoCheckbox = useRef();
    const urduCheckbox = useRef();
    const crtnCheckbox = useRef();

    const reportRef = useRef();

    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const tableRef = useRef();


    const formData = useRef(null);
    const [totalBarcodeLabels, setTotalBarcodeLabels] = useState(0);


    const columns = useRef([
        // { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '300px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'MP', row: "marketPrice", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Crtn Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '200px' } },
    ]);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadBarcodeLabels().then((result) => {
            try {
                if (isActive.current) {
                    setTotalBarcodeLabels(result.total.totalBarcodeLabels);
                    tableRef.current.setData([...result.rows]);
                    tableRef.current.setLoading(false);
                }
            } catch (error) {
                if (isActive.current) {
                    tableRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateBarcodeLabel(data)
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            // toast.current.show("Barcode Label updated successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            } else {
                apiInsertBarcodeLabel(getFormData())
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            // toast.current.show("Barcode Label saved successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetBarcodeLabel(tempId)
                .then((result) => {
                    if (isActive.current) {
                        updateId.current = tempId;
                        isUpdate.current = true;

                        setFormData(result);
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        messageBox.current.show(err.message, "Error", "e");
                    }
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Barcode Label?")) {
                apiDeleteBarcodeLabel(Number(row['id']))
                    .then((result) => {
                        if (isActive.current) {
                            // toast.current.show("Barcode Label deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Order?")) {
                apiDeleteBarcodeLabel(updateId.current)
                    .then((result) => {
                        if (isActive.current) {
                            // toast.current.show("Barcode Label deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        }
    }
    const clearBarcodeLabels = () => {
        if (tableRef.current.getData().length > 0) {
            if (window.confirm("Are you sure to delete all these Barcode Label?")) {
                apiClearBarcodeLabel()
                    .then((result) => {
                        if (isActive.current) {
                            // toast.current.show("Barcode Label deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        };
    }
    const clearTextboxes = () => {
        barcodeItemnameTB.current.setText("");
        qtyTB.current.setText("");
        itemnameTB.current.setText("");
        barcodeTB.current.setText("");
        rateTB.current.setText("");
        crtnRateTB.current.setText("");
        marketPriceTB.current.setText("");
        expiryTB.current.setText(new Date());
        crtnCheckbox.current.setChecked(false);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;
        itemId.current = 0;

        barcodeItemnameTB.current.focus();
    }
    const setFormData = (barcodeLabels) => {
        formData.current = barcodeLabels;

        itemId.current = barcodeLabels.itemId;

        barcodeItemnameTB.current.setText(barcodeLabels.originalItemname);
        qtyTB.current.setText(barcodeLabels.qty);
        itemnameTB.current.setText(barcodeLabels.itemname);
        barcodeTB.current.setText(barcodeLabels.barcode);
        rateTB.current.setText(barcodeLabels.rate);
        crtnRateTB.current.setText(barcodeLabels.crtnRate);
        marketPriceTB.current.setText(barcodeLabels.marketPrice);
        expiryTB.current.setText(barcodeLabels.expiry);

        qtyTB.current.focus();
    }
    const showDataIntoTextboxes = (item) => {
        formData.current = item;
        itemId.current = item.id;

        qtyTB.current.setText(1);

        // Set Itemname
        barcodeItemnameTB.current.setText(item.itemname);
        if (urduCheckbox.current.isChecked())
            itemnameTB.current.setText(item.urduname);
        else
            itemnameTB.current.setText(item.itemname);

        // Set Barcode
        if (item.isCrtnBarcode)
            barcodeTB.current.setText(item.crtnBarcode);
        else
            barcodeTB.current.setText(item.barcode);

        // Set Rate
        if (item.isCrtnBarcode)
            rateTB.current.setText(item.crtnRate);
        else
            rateTB.current.setText(item.retail);
        crtnRateTB.current.setText(item.crtnRate);
        marketPriceTB.current.setText(item.marketPrice);
        expiryTB.current.setText(item.expiry);

        if (autoCheckbox.current.isChecked()) {
            setTimeout(() => {
                insertOrUpdateData();
            }, 100);
        } else
            qtyTB.current.focus();
    }
    const getFormData = () => {
        const barcodeLabels = {
            itemId: itemId.current,
            qty: convertToInt(qtyTB.current.getText()),
            itemname: itemnameTB.current.getText(),
            barcode: barcodeTB.current.getText(),
            rate: convertToNumber(rateTB.current.getText()),
            crtnRate: convertToNumber(crtnRateTB.current.getText()),
            marketPrice: convertToNumber(marketPriceTB.current.getText()),
            expiry: expiryTB.current.getText(),
        }

        return barcodeLabels;
    }
    const validation = async () => {
        const isValid = await isValidBarcodeLabel();
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidBarcodeLabel = async () => {
        if (itemId.current === 0) {
            toast.current.show("Please Select Item.", "i");
            return false;
        }

        if (itemnameTB.current.getText() === '') {
            toast.current.show("Please Enter Itemname.", "i");
            itemnameTB.current.focus();
            return false;
        }

        if (barcodeTB.current.getText() === '') {
            toast.current.show("Please Enter Barcode.", "i");
            barcodeTB.current.focus();
            return false;
        }

        if (convertToNumber(qtyTB.current.getText()) === 0) {
            toast.current.show("Please Enter No of Label to Print.", "i");
            qtyTB.current.focus();
            return false;
        }

        return true;
    }
    const showSearchItemsForm = () => {
        setActiveComponent('search');
    }
    const pageStyle = `@media print {
                                @page{
                                    width: 216px;
                                    height: auto;
                                    margin: 0px;
                                }
                            }`;
    const printBill = useReactToPrint({
        content: () => reportRef.current,
        pageStyle:pageStyle,
        print: async (printIframe) => {
            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        onAfterPrint: () => {
            // setBillData([]);
            // barcodeLabelNameTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }
    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    const onClearBarcodesClick = () => {
        clearBarcodeLabels();
    }
    const onPrintBtnClick = () => {
        if(tableRef.current.getData().length > 0){
            printBill();
        }
    }
    const changeExpiry = (months) => {
        expiryTB.current.setText(addDays(new Date(), 12 * months));
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "utilities")
            return;

        if (activeTab.current !== "barcodelabels")
            return;

        if (!isActive.current)
            return;


        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            onPrintBtnClick();
        } else if (e.ctrlKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            clearBarcodeLabels();
        } else if (e.altKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            clearBarcodeLabels();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "f1") {
            e.preventDefault();
            showSearchItemsForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        } else if (e.altKey && e.key.toLowerCase() === "c") {
            e.preventDefault();
            changeAutoCheckbox();
        } else if (e.ctrlKey && e.key.toLowerCase() === "u") {
            e.preventDefault();
            changeUrduCheckbox();
        } else if (e.ctrlKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            changeCrtnCheckbox();
        }
    }
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const text = e.target.value;
            if (isExpression(text)) {
                try {
                    switch (e.currentTarget.id) {
                        case "tb1502": //qty
                            qtyTB.current.setText(evaluate(text));
                            break;
                        case "tb1505": //rate
                            rateTB.current.setText(evaluate(text));
                            break;
                        case "tb1506": //crtnRate
                            crtnRateTB.current.setText(evaluate(text));
                            break;
                        default:
                            break;
                    }
                } catch (err) {

                }
            } else {
                selectNextElement(e);
            }
        }
    }
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onUrduCheckboxChange = () => {
        if (formData.current !== null) {
            if (urduCheckbox.current.isChecked())
                itemnameTB.current.setText(formData.current.urduname);
            else {
                if (isUpdate.current)
                    itemnameTB.current.setText(formData.current.originalItemname);
                else
                    itemnameTB.current.setText(formData.current.itemname);
            }
        }
    }
    const onCrtnCheckboxChange = () => {
        if (formData.current !== null) {
            if (crtnCheckbox.current.isChecked())
                barcodeTB.current.setText(formData.current.crtnBarcode);
            else
                barcodeTB.current.setText(formData.current.barcode);
        }
    }
    const changeAutoCheckbox = () => {
        autoCheckbox.current.setChecked(!autoCheckbox.current.isChecked());
    }
    const changeUrduCheckbox = () => {
        urduCheckbox.current.setChecked(!urduCheckbox.current.isChecked());
    }
    const changeCrtnCheckbox = () => {
        crtnCheckbox.current.setChecked(!crtnCheckbox.current.isChecked());
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "barcodelabels" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "barcodelabels" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "search":
                            return <SearchItemsComponent
                                ref={searchItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onItemSelect={showDataIntoTextboxes}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div className='leftPanel'>
                        <div className="dRow">
                            <div style={{ width: '100%' }}>
                                <AutocompleteTextbox
                                    ref={barcodeItemnameTB}
                                    label="Select Item"
                                    tabIndex={1501}
                                    onEnter={showDataIntoTextboxes}
                                    style={{}} />
                            </div>
                            <Checkbox
                                ref={autoCheckbox}
                                label="Auto"
                                style={{ minWidth: '70px' }}
                                className="defaultMarginLeft"
                                onChange={() => { }} />
                        </div>
                        <div className="dRow">
                            <Textbox
                                ref={qtyTB}
                                label="No of labels to print"
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: '1' }}
                                tabIndex={1502} />
                            <Checkbox
                                // ref={urduCheckbox}
                                label="Urdu"
                                style={{ minWidth: '70px' }}
                                className="invisible defaultMarginLeft"
                                onChange={() => { }} />
                        </div>
                        <div className="dRow">
                            <Textbox
                                ref={itemnameTB}
                                label="Itemname"
                                style={{ flex: '1' }}
                                onKeyDown={onTextboxesKeyDown}
                                tabIndex={1503} />
                            <Checkbox
                                ref={urduCheckbox}
                                label="Urdu"
                                style={{ minWidth: '70px' }}
                                className="defaultMarginLeft"
                                onChange={onUrduCheckboxChange} />
                        </div>
                        <div className="dRow">
                            <Textbox
                                ref={barcodeTB}
                                label="Barcode"
                                style={{ flex: '1' }}
                                onKeyDown={onTextboxesKeyDown}
                                tabIndex={1504} />
                            <Checkbox
                                ref={crtnCheckbox}
                                label="Crtn"
                                style={{ minWidth: '70px' }}
                                className="defaultMarginLeft"
                                onChange={onCrtnCheckboxChange} />
                        </div>

                        <div className="dRow">
                            <Textbox
                                ref={rateTB}
                                label="Rate"
                                style={{ flex: '1' }}
                                onKeyDown={onTextboxesKeyDown}
                                tabIndex={1505} />
                            <Textbox
                                ref={crtnRateTB}
                                label="Crtn Rate"
                                style={{ flex: '1' }}
                                className={`defaultMarginLeft ${settings.saleCartons ? "d-block" : "d-none"}`}
                                onKeyDown={onTextboxesKeyDown}
                                tabIndex={1506} />
                            <Textbox
                                ref={marketPriceTB}
                                label="MP"
                                style={{ flex: '1' }}
                                className="defaultMarginLeft"
                                onKeyDown={onLastTextboxKeyDown}
                                tabIndex={1507} />
                        </div>

                        <div className="dRow">
                            <DateTimePicker
                                ref={expiryTB}
                                label="Expiry"
                                time={false}
                                style={{ flex: 'auto' }}
                                tabIndex={100} />

                            <div className="dRow defaultMarginLeft">
                                <MiniButton
                                    label="24"
                                    style={{ marginTop: '8px' }}
                                    onClick={() => { changeExpiry(24) }} />

                                <MiniButton
                                    label="12"
                                    style={{ marginTop: '8px' }}
                                    onClick={() => { changeExpiry(12) }} />

                                <MiniButton
                                    label="6"
                                    style={{ marginTop: '8px' }}
                                    onClick={() => { changeExpiry(6) }} />

                                <MiniButton
                                    label="3"
                                    style={{ marginTop: '8px' }}
                                    onClick={() => { changeExpiry(3) }} />

                                <MiniButton
                                    label="1"
                                    style={{ marginTop: '8px', marginRight: '0px' }}
                                    onClick={() => { changeExpiry(1) }} />
                            </div>
                        </div>

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            isActive={isActive}
                            onDoubleClick={onEditBtnClick}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                        <div className='dRow jce' style={{ marginBottom: '-18px' }}>
                            <div className='refreshBtn' onClick={loadData}>
                                <img src="/icons/refresh.png" alt="" />
                            </div>
                            <ButtonIcon
                                label="Print"
                                icon="icons/buttons/print.png"
                                style={{ margin: '0px', marginRight: '6px' }}
                                onClick={onPrintBtnClick} />
                            <ButtonIcon
                                label="Clear"
                                icon="icons/buttons/delete.png"
                                style={{ margin: '0px' }}
                                onClick={onClearBarcodesClick} />
                        </div>

                        <div className="tableFooter" style={{ maxWidth: '200px' }}>
                            <p><b>Total Items: </b>{totalBarcodeLabels}</p>
                        </div>
                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='rightPanel'>
                        {/* Report & Viewer */}
                        <div className={`reportViewer`} >
                            {(() => {
                                switch (activeReport) {
                                    case "barcodeitemname":
                                        return <BarcodeItemnameLabel
                                            ref={reportRef}
                                            messageBox={messageBox} />;
                                    default:
                                        return <div></div>;
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BarcodeLabelsComponent;