/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import { evaluate } from "mathjs";
import {
    apiLoadItems,
    apiInsertItem,
    apiUpdateItem,
    apiDeleteItem,
    apiGetItem,
    apiValidation,
    apiUploadImage
} from "api/items/ItemsApi";
import { selectNextElement, convertToNumber, isExpression, selectNextElement2, convertToInt, isFileSizeValid, addDays } from "utilities/Utils";
import 'style/items/items.css';
import Table from 'components/tools/Table';
import ComboBox from 'components/tools/ComboBox';
import Checkbox from 'components/tools/Checkbox';
import DateTimePicker from 'components/tools/DateTimePicker';
import Textbox from 'components/tools/Textbox';
import AutocompleteTextbox from 'components/tools/AutocompleteTextbox';
import TextboxNoLabel from 'components/tools/TextboxNoLabel';
import Label from 'components/tools/Label';
import TextboxCB from 'components/tools/TextboxCB';
import ButtonIcon from 'components/tools/ButtonIcon';
import Pagination from "components/tools/Pagination";
import VendorCB from "components/tools/dropdowns/VendorCB";
import CategoryCB from "components/tools/dropdowns/CategoryCB";
import SubCategoryCB from "components/tools/dropdowns/SubCategoryCB";
import Image from "components/tools/Image";
import SubBarcodesComponent from "components/items/SubBarcodesComponent";
import LinkedItemsComponent from "components/items/LinkedItemsComponent";
import PackagesComponent from "./PackagesComponent";
import { useSelector } from 'react-redux';
import ThinButton from "components/tools/ThinButton";
import { getClientId } from "utilities/DB";
import MiniButton from "components/tools/MiniButton";
/* #endregion Imports */

const ItemsComponent = (props) => {

    /* #region Variables */
    const formId = "itemsForm";
    const isActive = useRef(true);
    const mainActiveTab = useRef(null);
    const settings = useSelector(state => state.main.settings);
    const passwords = useSelector(state => state.main.passwords);
    const user = useSelector(state => state.main.user);

    const barcodeTB = useRef();
    const refCodeTB = useRef();
    const itemnameTB = useRef();
    const urdunameTB = useRef();
    const costTB = useRef();
    const marginTB = useRef();
    const retailTB = useRef();
    const wholesaleTB = useRef();
    const rate3TB = useRef();
    const rate4TB = useRef();
    const crtnRateTB = useRef();
    const crtnSizeTB = useRef();
    const marketPriceTB = useRef();
    const discTB = useRef();
    const locationTB = useRef();
    const expirableCheckbox = useRef();
    const expiryTB = useRef();
    const categoryCB = useRef();
    const subCategoryCB = useRef();
    const vendorCB = useRef();
    const stockWarningMinTB = useRef();
    const stockWarningMaxTB = useRef();
    const openingStockPcsTB = useRef();
    const openingStockCrtnTB = useRef();
    const repeatableCheckbox = useRef();
    const searchableCheckbox = useRef();
    const buttonCheckbox = useRef();
    const lockPcsCheckbox = useRef();
    const lockCrtnCheckbox = useRef();
    const saleUnderStockCheckbox = useRef();
    const currentStockPcsLB = useRef();
    const currentStockCrtnLB = useRef();

    // Search References
    const searchCategoryCB = useRef();
    const searchSubCategoryCB = useRef();
    const searchVendorCB = useRef();
    const searchTB = useRef();
    const searchByCB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const paginationRef = useRef();
    const [totalItems, setTotalItems] = useState(0);
    const [activeComponent, setActiveComponent] = useState(null);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);
    const [showUpdateNewRatesBtn, setShowUpdateNewRatesBtn] = useState(false);


    // Modals
    const subBarcodesFormRef = useRef(null);
    const linkedItemsFormRef = useRef(null);
    const packagesFormRef = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '200px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const columnsC = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '200px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const columnsF = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Retail', row: "retail", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'W.Sale', row: "wholesale", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate3', row: "rate3", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate4', row: "rate4", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Barcode', row: "barcode", sortBy: "string", style: { minWidth: '95px' } },
        // { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '95px' } },
        { column: 'Category', row: "categoryName", sortBy: "string", style: { minWidth: '200px' } },
        // { column: 'Sub-Category', row: "subCategoryName", sortBy: "string", style: { minWidth: '120px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Urduname', row: "urduname", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'RefCode', row: "refCode", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    const searchBy = useRef([
        { id: 0, name: 'By Name' },
        { id: 1, name: 'By Barcode' },
        { id: 2, name: 'By Ref. Code' },
        { id: 3, name: 'By Urduname' },
        { id: 4, name: 'By Cost' },
        { id: 5, name: 'By Retail' },
        { id: 6, name: 'By Wholesale' },
        { id: 7, name: 'By Carton Rate' },
        { id: 8, name: 'By Carton Size' },
    ]);
    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadSettings();
        loadData();

        barcodeTB.current.focus();
    }
    const loadSettings = () => {
        openingStockPcsTB.current.disable();
        openingStockCrtnTB.current.disable();
        discTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);

        if (!settings.saleCartons) {
            crtnRateTB.current.disable();
            crtnSizeTB.current.disable();
        }
    }
    const loadData = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadItems({
            page: page,
            itemsPerPage: itemsPerPage,
            categoryId: searchCategoryCB.current.getValue(),
            subCategoryId: searchSubCategoryCB.current.getValue(),
            vendorId: searchVendorCB.current.getValue(),
            searchBy: searchByCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalItems(result.total.totalItems);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async (updateNewStock = false) => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();
        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData(updateNewStock);
                data.id = updateId.current;
                apiUpdateItem(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Item updated successfully.", "s");
                        clearTextboxes();
                        loadData();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertItem(getFormData(updateNewStock))
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Item saved successfully.", "s");
                        clearTextboxes();
                        loadData();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetItem(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteItem) {
                passwordBox.current.show("deleteItem", (result) => {
                    if (result) {
                        apiDeleteItem(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Item deleted successfully.", "s");
                                loadData();
                                // clearTextboxes();
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this item?")) {
                    apiDeleteItem(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Item deleted successfully.", "s");
                            loadData();
                            // clearTextboxes();
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this item?")) {
                apiDeleteItem(updateId.current)
                    .then((result) => {
                        toast.current.show("Item deleted successfully.", "s");
                        clearTextboxes();
                        loadData();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        barcodeTB.current.setText("");
        refCodeTB.current.setText("");
        itemnameTB.current.setText("");
        urdunameTB.current.setText("");
        costTB.current.setText("");
        marginTB.current.setText("");
        retailTB.current.setText("");
        wholesaleTB.current.setText("");
        rate3TB.current.setText("");
        rate4TB.current.setText("");
        crtnRateTB.current.setText("");
        crtnSizeTB.current.setText("");
        marketPriceTB.current.setText("");
        discTB.current.setText("");
        discTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        locationTB.current.setText("");
        expirableCheckbox.current.setChecked(false);

        expiryTB.current.setText(new Date());
        categoryCB.current.setValue(0);
        subCategoryCB.current.setValue(0);
        vendorCB.current.setValue(0);
        stockWarningMinTB.current.setText("");
        stockWarningMaxTB.current.setText("");
        openingStockPcsTB.current.setText("");
        openingStockCrtnTB.current.setText("");

        repeatableCheckbox.current.setChecked(false);
        searchableCheckbox.current.setChecked(true);
        buttonCheckbox.current.setChecked(false);
        lockPcsCheckbox.current.setChecked(false);
        lockCrtnCheckbox.current.setChecked(false);
        saleUnderStockCheckbox.current.setChecked(true);

        currentStockPcsLB.current.setText("");
        currentStockCrtnLB.current.setText("");

        setShowUpdateNewRatesBtn(false);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        document.getElementById("itemImageDialog").value = "";
        document.getElementById("itemImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;


        barcodeTB.current.focus();
    }
    const setFormData = (item) => {
        formData.current = item;

        barcodeTB.current.setText(item.barcode);
        refCodeTB.current.setText(item.refCode);
        itemnameTB.current.setText(item.itemname);
        urdunameTB.current.setText(item.urduname);
        costTB.current.setText(item.cost);
        retailTB.current.setText(item.retail);
        wholesaleTB.current.setText(item.wholesale);
        rate3TB.current.setText(item.rate3);
        rate4TB.current.setText(item.rate4);
        crtnRateTB.current.setText(item.crtnRate);
        crtnSizeTB.current.setText(item.crtnSize);
        marketPriceTB.current.setText(item.marketPrice);
        discTB.current.setText(item.disc);
        discTB.current.setCBValue(item.isDiscRsPer ? 1 : 0);
        locationTB.current.setText(item.location);
        expirableCheckbox.current.setChecked(item.expirable);

        expiryTB.current.setText(item.expiry);

        categoryCB.current.setValue(item.categoryId);
        setTimeout(() => {
            subCategoryCB.current.setValue(item.subCategoryId);
        }, 200);
        vendorCB.current.setValue(item.vendorId);
        stockWarningMinTB.current.setText(item.stockWarningMin);
        stockWarningMaxTB.current.setText(item.stockWarningMax);

        repeatableCheckbox.current.setChecked(item.repeatable);
        searchableCheckbox.current.setChecked(item.searchable);
        buttonCheckbox.current.setChecked(item.button);
        lockPcsCheckbox.current.setChecked(item.lockPcs);
        lockCrtnCheckbox.current.setChecked(item.lockCrtn);
        saleUnderStockCheckbox.current.setChecked(item.saleUnderStock);

        currentStockPcsLB.current.setText(item.stockPcs);
        currentStockCrtnLB.current.setText(item.stockCrtn);

        const newStock = item.newStockPcs + (item.newStockCrtn * item.newCrtnSize);
        if (newStock > 0) {
            setShowUpdateNewRatesBtn(true);
        }

        itemnameTB.current.focus();

        if (item.imageUrl !== '')
            document.getElementById("itemImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${item.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("itemImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = (updateNewStock = false) => {
        const item = {
            barcode: barcodeTB.current.getText().trim(),
            itemname: itemnameTB.current.getText().trim(),
            urduname: urdunameTB.current.getText(),
            refCode: refCodeTB.current.getText(),
            cost: convertToNumber(costTB.current.getText()),
            retail: convertToNumber(retailTB.current.getText()),
            wholesale: convertToNumber(wholesaleTB.current.getText()),
            rate3: convertToNumber(rate3TB.current.getText()),
            rate4: convertToNumber(rate4TB.current.getText()),
            crtnRate: convertToNumber(crtnRateTB.current.getText()),
            crtnSize: convertToInt(crtnSizeTB.current.getText()),
            marketPrice: convertToNumber(marketPriceTB.current.getText()),
            expirable: expirableCheckbox.current.isChecked(),
            expiry: expiryTB.current.getText(),
            disc: convertToNumber(discTB.current.getText()),
            isDiscRsPer: discTB.current.getCBValue() === 1 ? true : false,
            stockWarningMin: convertToNumber(stockWarningMinTB.current.getText()),
            stockWarningMax: convertToNumber(stockWarningMaxTB.current.getText()),
            location: locationTB.current.getText(),
            categoryId: categoryCB.current.getValue(),
            subCategoryId: categoryCB.current.getValue() === 0 ? 0 : subCategoryCB.current.getValue(),
            vendorId: vendorCB.current.getValue(),
            repeatable: repeatableCheckbox.current.isChecked(),
            searchable: searchableCheckbox.current.isChecked(),
            button: buttonCheckbox.current.isChecked(),
            lockPcs: lockPcsCheckbox.current.isChecked(),
            lockCrtn: lockCrtnCheckbox.current.isChecked(),
            saleUnderStock: saleUnderStockCheckbox.current.isChecked(),
            openingStockPcs: convertToNumber(openingStockPcsTB.current.getText()),
            openingStockCrtn: convertToInt(openingStockCrtnTB.current.getText()),
        }

        if (updateNewStock) {
            item.newCost = 0;
            item.newRetail = 0;
            item.newWholesale = 0;
            item.newRate3 = 0;
            item.newRate4 = 0;
            item.newMarketPrice = 0;
            item.newCrtnRate = 0;
            item.newCrtnSize = 0;
            item.newStockPcs = 0;
            item.newStockCrtn = 0;
        }

        return item;
    }
    const showSubBarcodesForm = () => {
        if (isUpdate.current) {
            setActiveComponent('subBarcodes');
        }
    }
    const showLinkedItemsForm = () => {
        if (isUpdate.current) {
            setActiveComponent('linkedItems');
        }
    }
    const showPackagesForm = () => {
        setActiveComponent('packages');
    }
    const validation = async () => {
        const barcode = barcodeTB.current.getText().trim();
        const refCode = refCodeTB.current.getText();
        const itemname = itemnameTB.current.getText().trim();
        const result = await apiValidation(barcode, refCode, itemname);

        if (isValidBarcode(barcode, result.isBarcodeExists) &&
            isValidItemname(itemname, result.isItemnameExists) &&
            isValidRefCode(refCode, result.isRefCodeExists) &&
            isValidCartonSize())
            return true;
        else
            return false;
    }
    const isValidBarcode = (barcode, isExists) => {
        if (barcode === '') {
            toast.current.show("Please Enter Barcode.", "i");
            barcodeTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (barcode.toLowerCase() === formData.current.barcode.toLowerCase())
                    return true;
                else
                    return isBarcodeExists(isExists);
            }
            else
                return isBarcodeExists(isExists);
        }
    }
    const isValidItemname = (itemname, isExists) => {
        if (itemname === '') {
            toast.current.show("Please Enter Itemname.", "i");
            itemnameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (itemname.toLowerCase() === formData.current.itemname.toLowerCase())
                    return true;
                else
                    return isItemnameExists(isExists);
            }
            else
                return isItemnameExists(isExists);
        }
    }
    const isValidRefCode = (refCode, isExists) => {
        if (refCode.length > 0 && refCode.trim() === '') {
            toast.current.show("Please enter a valid RefCode.", "i");
            refCodeTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (refCode.toLowerCase() === formData.current.refCode.toLowerCase())
                    return true;
                else
                    return isRefCodeExists(isExists);
            }
            else
                return isRefCodeExists(isExists);
        }
    }
    const isBarcodeExists = (isExists) => {
        if (isExists) {
            toast.current.show("Barcode already exists.", "i");
            barcodeTB.current.focus();
            return false;
        } else
            return true;
    }
    const isItemnameExists = (isExists) => {
        if (isExists) {
            toast.current.show("Itemname already exists.", "i");
            itemnameTB.current.focus();
            return false;
        } else
            return true;
    }
    const isRefCodeExists = (isExists) => {
        if (isExists) {
            toast.current.show("RefCode already exists.", "i");
            refCodeTB.current.focus();
            return false;
        } else
            return true;
    }
    const isValidCartonSize = () => {
        let isAllOk = false;

        let retail = convertToNumber(retailTB.current.getText());
        let wholesale = convertToNumber(wholesaleTB.current.getText());
        let crtnRate = convertToNumber(crtnRateTB.current.getText());
        let crtnSize = convertToNumber(crtnSizeTB.current.getText());

        if (retail === 0 && wholesale === 0 && crtnRate !== 0) {
            crtnSizeTB.current.setText("1");
            isAllOk = true;
        }
        else if (retail === 0 && wholesale === 0 && crtnRate === 0) {
            crtnSizeTB.current.setText("0");
            isAllOk = true;
        }
        else if ((retail !== 0 || wholesale !== 0) && crtnRate === 0) {
            crtnSizeTB.current.setText("0");
            isAllOk = true;
        }
        else if ((retail !== 0 || wholesale !== 0) && crtnRate !== 0) {
            if (crtnSizeTB.current.getText() === '') {
                toast.current.show("Please enter how many pieces in carton.", "i");
                crtnSizeTB.current.focus();
                isAllOk = false;
            }
            else {
                if (crtnSize < 2) {
                    toast.current.show("Pieces in carton should be more than 1", "i");
                    crtnSizeTB.current.focus();
                    isAllOk = false;
                }
                else
                    isAllOk = true;
            }
        }

        return isAllOk;
    }
    const onImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if (imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)) {
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("itemImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image updated successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }
    const onSubBtnClick = () => {
        showSubBarcodesForm();
    }
    const onLinkedBtnClick = () => {
        showLinkedItemsForm();
    }
    const onPackageBtnClick = () => {
        showPackagesForm();
    }
    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    const onUpdateNewRatesBtnClick = () => {
        if (isUpdate.current && formData.current !== null) {

            costTB.current.setText(formData.current.newCost);
            retailTB.current.setText(formData.current.newRetail);
            wholesaleTB.current.setText(formData.current.newWholesale);
            crtnRateTB.current.setText(formData.current.newCrtnRate);
            crtnSizeTB.current.setText(formData.current.newCrtnSize);

            setTimeout(() => {
                if (window.confirm("Continue to Update?")) {
                    insertOrUpdateData(true);
                }
            }, 500);
        }
    }
    const onUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    const changeExpiry = (months) => {
        expiryTB.current.setText(addDays(new Date(), 12 * months));
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "items")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            showSubBarcodesForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "l") {
            e.preventDefault();
            showLinkedItemsForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            showPackagesForm();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        } else if (e.altKey && e.key.toLowerCase() === "l") {
            e.preventDefault();
            lockPcsCheckbox.current.setChecked(!lockPcsCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "k") {
            e.preventDefault();
            lockCrtnCheckbox.current.setChecked(!lockCrtnCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            buttonCheckbox.current.setChecked(!buttonCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            repeatableCheckbox.current.setChecked(!repeatableCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            saleUnderStockCheckbox.current.setChecked(!saleUnderStockCheckbox.current.isChecked());
        }
    }
    // Textboxes Keydown
    const onBarcodeTBKeyDown = (data) => {
        const item = data;
        updateId.current = item.id;
        isUpdate.current = true;
        setFormData(item);
    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            const text = e.target.value;
            if (isExpression(text)) {
                try {
                    switch (e.currentTarget.id) {
                        case "tb5":
                            costTB.current.setText(evaluate(text));
                            break;
                        case "tb6":
                            retailTB.current.setText(evaluate(text));
                            break;
                        case "tb7":
                            wholesaleTB.current.setText(evaluate(text));
                            break;
                        case "tb8":
                            rate3TB.current.setText(evaluate(text));
                            break;
                        case "tb9":
                            rate4TB.current.setText(evaluate(text));
                            break;
                        case "tb10":
                            crtnRateTB.current.setText(evaluate(text));
                            break;
                        case "tb11":
                            crtnSizeTB.current.setText(evaluate(text));
                            break;
                        case "tb12":
                            marketPriceTB.current.setText(evaluate(text));
                            break;
                        default:
                            break;
                    }
                } catch (err) {

                }

            } else {
                const id = e.target.id;
                if (id === "tb7") {
                    if (settings.fourRateSystem === false) {
                        selectNextElement2(10);
                    }
                } else if (id === "tb9") {
                    if (settings.fourRateSystem === true) {
                        selectNextElement2(12);
                    }
                }

                selectNextElement(e);
            }
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Category Combobox Change
    const onCategoryCBChange = (selectedValue, selectedText) => {
        subCategoryCB.current.loadData(selectedValue);
    }
    const onSearchCategoryCBChange = (selectedValue, selectedText) => {
        searchSubCategoryCB.current.loadData(selectedValue);
        loadData();
    }
    // On Sub-Category Combobox Change
    const onSearchSubCategoryCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    // On Vendor Combobox Change
    const onSearchVendorCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    // On Search By Combobox Change
    const onSearchByCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    const onRetailTBTextChange = () => {
        const retail = convertToNumber(retailTB.current.getText());
        const wholesale = convertToNumber(wholesaleTB.current.getText());

        if (user.branchGroupId === 0) {
            if (retail === 0 && wholesale === 0) {
                openingStockPcsTB.current.disable();
                openingStockPcsTB.current.setText("");
            }
            else {
                openingStockPcsTB.current.enable();
            }
        }
    }
    const onCrtnRateTBTextChange = () => {
        const crtnRate = convertToNumber(crtnRateTB.current.getText());

        if (user.branchGroupId === 0) {
            if (crtnRate > 0) {
                openingStockCrtnTB.current.enable();
            }
            else {
                openingStockCrtnTB.current.disable();
                openingStockCrtnTB.current.setText("");
            }
        }
    }
    const onMarginTBTextChange = () => {
        const cost = convertToNumber(costTB.current.getText());
        if (cost > 0) {
            const margin = convertToNumber(marginTB.current.getText());
            const rate = ((margin / 100) * cost) + cost;
            retailTB.current.setText(rate);
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "items" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>


            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "subBarcodes":
                            return <SubBarcodesComponent
                                ref={subBarcodesFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                itemId={updateId.current}
                                setActiveComponent={setActiveComponent}
                                mainActiveTab={props.mainActiveTab}
                            />
                        case "linkedItems":
                            return <LinkedItemsComponent
                                ref={linkedItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                item={formData.current}
                                setActiveComponent={setActiveComponent}
                                mainActiveTab={props.mainActiveTab}
                            />
                        case "packages":
                            return <PackagesComponent
                                ref={packagesFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                mainActiveTab={props.mainActiveTab}
                            />
                        // case "rejectedItems":
                        //     return <RejectedItemsComponent
                        //         messageBox={messageBox}
                        //         toast={toast}
                        //         isActive={isActive}
                        //         mainActiveTab={props.mainActiveTab}
                        //         setActiveComponent={setActiveComponent}
                        //         onItemSelect={onBarcodeTBKeyDown}
                        //     />
                        default:
                            return <div></div>;
                    }
                })()}

            </div>

            <div id={formId} className="mainContainer containerWidth">
                <div style={{ display: 'flex' }}>
                    {/* Left */}
                    <div style={{ minWidth: '400px', maxWidth: '500px', width: '100%', flex: '1' }}>
                        <div style={{ display: 'flex' }}>
                            <AutocompleteTextbox
                                ref={barcodeTB}
                                label="Barcode"
                                tabIndex={1}
                                onEnter={onBarcodeTBKeyDown}
                                onBarcodeNotFound={() => { itemnameTB.current.focus() }}
                                showAlert={false}
                                style={{ width: '270px' }} />

                            <Textbox
                                ref={refCodeTB}
                                label="RefCode"
                                tabIndex={2}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }}
                                className="defaultMarginLeft" />
                        </div>

                        <Textbox
                            ref={itemnameTB}
                            label="Itemname"
                            tabIndex={3}
                            onKeyDown={onTextboxesKeyDown} />

                        <div className="urdunameTB">
                            <Textbox
                                ref={urdunameTB}
                                label="Urduname"
                                tabIndex={4}
                                onKeyDown={onTextboxesKeyDown} />
                        </div>

                        <div style={{ display: 'flex' }}>

                            <Textbox
                                ref={costTB}
                                label="Cost"
                                tabIndex={5}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }} />

                            <Textbox
                                ref={marginTB}
                                label="Margin %"
                                style={{ flex: 'auto' }}
                                onChange={onMarginTBTextChange}
                                className="defaultMarginLeft" />

                        </div>

                        <div style={{ display: 'flex' }}>
                            <Textbox
                                ref={retailTB}
                                label="Retail"
                                tabIndex={6}
                                onChange={onRetailTBTextChange}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }} />

                            <Textbox
                                ref={crtnRateTB}
                                label="Carton Rate"
                                tabIndex={10}
                                onChange={onCrtnRateTBTextChange}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }}
                                onFocus={() => {
                                    if (!settings.saleCartons) {
                                        itemnameTB.current.focus();
                                    }
                                }}
                                className={`defaultMarginLeft ${!settings.fourRateSystem ? "d-block" : "d-none"}`} />

                            <Textbox
                                ref={rate3TB}
                                label="Rate 3"
                                tabIndex={8}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }}
                                className={`defaultMarginLeft ${settings.fourRateSystem ? "d-block" : "d-none"}`} />
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Textbox
                                ref={wholesaleTB}
                                label="Wholesale"
                                tabIndex={7}
                                onChange={onRetailTBTextChange}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }} />

                            <Textbox
                                ref={crtnSizeTB}
                                label="PCS in Carton"
                                tabIndex={11}
                                onKeyDown={onTextboxesKeyDown}
                                onFocus={() => {
                                    if (!settings.saleCartons) {
                                        itemnameTB.current.focus();
                                    }
                                }}
                                style={{ flex: 'auto' }}

                                className={`defaultMarginLeft ${!settings.fourRateSystem ? "d-block" : "d-none"}`} />

                            <Textbox
                                ref={rate4TB}
                                label="Rate 4"
                                tabIndex={9}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }}
                                className={`defaultMarginLeft ${settings.fourRateSystem ? "d-block" : "d-none"}`} />
                        </div>

                        <div style={{ display: 'flex' }}>

                            <Textbox
                                ref={marketPriceTB}
                                label="Market Price"
                                tabIndex={12}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }} />

                            <TextboxCB
                                ref={discTB}
                                label="Discount"
                                tabIndex={13}
                                onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }}
                                className="defaultMarginLeft" />
                        </div>

                        <Textbox
                            ref={locationTB}
                            label="Location"
                            tabIndex={14}
                            onKeyDown={onTextboxesKeyDown} />

                    </div>
                    {/* Right */}
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                        <div style={{ display: 'flex' }}>
                            {/* Middle */}
                            <div className="defaultMarginLeft" style={{ minWidth: '320px', maxWidth: '400px', width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    <Checkbox
                                        ref={expirableCheckbox}
                                        label="Expirable"
                                        onChange={() => { }} />

                                    <DateTimePicker
                                        ref={expiryTB}
                                        label="Expiry"
                                        time={false}
                                        tabIndex={100}
                                        style={{ flex: 'auto' }}
                                        className="defaultMarginLeft" />
                                </div>

                                <CategoryCB
                                    ref={categoryCB}
                                    onChange={onCategoryCBChange} />

                                <SubCategoryCB
                                    ref={subCategoryCB} />

                                <VendorCB
                                    ref={vendorCB} />

                                <div style={{ display: 'flex', marginTop: '3px' }}>
                                    <div className="headerTb">
                                        <label>Stock Warning</label>
                                        <div className="header">
                                            <label>Min</label>
                                            <label>Max</label>
                                        </div>
                                        <div className="doubleTb">
                                            <TextboxNoLabel
                                                ref={stockWarningMinTB}
                                                tabIndex={15}
                                                onKeyDown={onTextboxesKeyDown} />
                                            <TextboxNoLabel
                                                ref={stockWarningMaxTB}
                                                tabIndex={16}
                                                onKeyDown={onLastTextboxKeyDown} />
                                        </div>
                                    </div>

                                    <div className="headerTb defaultMarginLeft">
                                        <label>Opening Stock</label>
                                        <div className="header">
                                            <label>PCS</label>
                                            <label>CRTN</label>
                                        </div>
                                        <div className="doubleTb">
                                            <TextboxNoLabel
                                                ref={openingStockPcsTB}
                                                tabIndex={17}
                                                onKeyDown={onTextboxesKeyDown} />
                                            <TextboxNoLabel
                                                ref={openingStockCrtnTB}
                                                tabIndex={18}
                                                onFocus={() => {
                                                    if (!settings.saleCartons) {
                                                        itemnameTB.current.focus();
                                                    }
                                                }}
                                                onKeyDown={onLastTextboxKeyDown} />
                                        </div>
                                    </div>
                                </div>

                                <div className="defaultMarginLeft" style={{ maxWidth: '400px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox
                                            ref={repeatableCheckbox}
                                            label="Repeatable"
                                            style={{ flex: '1' }} />

                                        <Checkbox
                                            ref={searchableCheckbox}
                                            label="Searchable"
                                            checked={true}
                                            className="defaultMarginLeft"
                                            style={{ flex: '1' }} />

                                        <Checkbox
                                            ref={buttonCheckbox}
                                            label="Button"
                                            className="defaultMarginLeft"
                                            style={{ flex: '1' }} />
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <Checkbox
                                            ref={lockPcsCheckbox}
                                            label="Lock PCS"
                                            style={{ flex: '1' }} />

                                        <Checkbox
                                            ref={lockCrtnCheckbox}
                                            label="Lock CRTN"
                                            className="defaultMarginLeft"
                                            style={{ flex: '1' }} />

                                        <Checkbox
                                            ref={saleUnderStockCheckbox}
                                            label="Sale Under Stock"
                                            checked={true}
                                            className="defaultMarginLeft"
                                            style={{ flex: '1' }} />
                                    </div>

                                </div>

                            </div>
                            {/* Right */}
                            <div className="dCol defaultMarginLeft" style={{ minWidth: '250px', maxWidth: '500px', flex: 1 }}>
                                <div className="dRow">
                                    <MiniButton
                                        label="24"
                                        style={{ marginTop: '8px' }}
                                        onClick={() => { changeExpiry(24) }} />

                                    <MiniButton
                                        label="12"
                                        style={{ marginTop: '8px' }}
                                        onClick={() => { changeExpiry(12) }} />

                                    <MiniButton
                                        label="6"
                                        style={{ marginTop: '8px' }}
                                        onClick={() => { changeExpiry(6) }} />

                                    <MiniButton
                                        label="3"
                                        style={{ marginTop: '8px' }}
                                        onClick={() => { changeExpiry(3) }} />

                                    <MiniButton
                                        label="1"
                                        style={{ marginTop: '8px' }}
                                        onClick={() => { changeExpiry(1) }} />
                                </div>

                                <Image
                                    width={240}
                                    height={144}
                                    id="itemImageId"
                                    style={{ marginTop: '16px' }}
                                    imageId="itemImage"
                                    imageDialogId="itemImageDialog"
                                    onUploadBtnClick={(e) => {
                                        onUploadBtnClick(e);
                                    }}
                                    onUpload={(e) => {
                                        onImageUpload(e);
                                    }} />

                                <div className="headerTb">
                                    <label>Current Stock</label>
                                    <div className="header">
                                        <label>Qty</label>
                                        <label>Crtn</label>
                                    </div>
                                    <div className="doubleTb">
                                        <Label
                                            ref={currentStockPcsLB} />
                                        <div></div>
                                        <Label
                                            ref={currentStockCrtnLB} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="buttons">
                    <ButtonIcon
                        label="Save"
                        icon="icons/buttons/save.png"
                        onClick={onSaveBtnClick} />

                    <ButtonIcon
                        label="Edit"
                        icon="icons/buttons/edit.png"
                        onClick={onEditBtnClick} />

                    <ButtonIcon
                        label="Delete"
                        icon="icons/buttons/delete.png"
                        onClick={onDeleteBtnClick} />

                    <ButtonIcon
                        label="Sub"
                        icon="icons/buttons/sub.png"
                        onClick={onSubBtnClick} />

                    <ButtonIcon
                        label="Linked"
                        icon="icons/buttons/linked.png"
                        onClick={onLinkedBtnClick} />

                    <ButtonIcon
                        label="Package"
                        icon="icons/buttons/package.png"
                        onClick={onPackageBtnClick} />

                    <ButtonIcon
                        label="Cancel"
                        icon="icons/buttons/cancel.png"
                        onClick={onCancelBtnClick} />

                    <ThinButton
                        label="Update New Rates"
                        style={{ width: '150px', alignSelf: 'end' }}
                        className={`${showUpdateNewRatesBtn ? 'd-block' : 'd-none'}`}
                        onClick={onUpdateNewRatesBtnClick} />
                </div>

                <div className="searchContainer1">
                    <CategoryCB
                        ref={searchCategoryCB}
                        onChange={onSearchCategoryCBChange} />

                    <SubCategoryCB
                        ref={searchSubCategoryCB}
                        style={{ width: '100%', flex: 'auto' }}
                        onChange={onSearchSubCategoryCBChange}
                        className="defaultMarginLeft" />

                    <VendorCB
                        ref={searchVendorCB}
                        onChange={onSearchVendorCBChange}
                        className="defaultMarginLeft" />
                </div>

                <div className="searchContainer2" style={{ display: 'flex' }}>

                    <Textbox
                        ref={searchTB}
                        label="Search"
                        onKeyDown={onSearchTBKeydown}
                        onChange={onSearchTBChange}
                        style={{ flex: 2 }}
                        tabIndex={19} />

                    <ComboBox
                        ref={searchByCB}
                        label="Search By"
                        className="searchBy defaultMarginLeft"
                        data={searchBy.current}
                        onChange={onSearchByCBChange}
                        style={{ flex: 1 }} />

                </div>

                <Table
                    ref={tableRef}
                    columns={settings.fourRateSystem ? columnsF.current : settings.saleCartons ? columnsC.current : columns.current}
                    myTable='itemsTable'
                    // autoSelectFirstRow={true}
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    onEnterKeyDown={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="paginationFooter">
                    <p><b>Total Items: </b>{totalItems}</p>
                    <Pagination
                        ref={paginationRef}
                        totalItems={totalItems}
                        reload={loadData}
                        showTotalLabel={false}
                        totalLabel="Total Items: " />
                </div>

            </div>
        </>
    )
};

export default ItemsComponent;