/* #region Imports */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import {
    apiLoadSubBarcodes,
    apiInsertSubBarcode,
    apiUpdateSubBarcode,
    apiDeleteSubBarcode,
    apiGetSubBarcode
} from "api/items/SubBarcodesApi";
import { apiIsBarcodeExists } from "api/items/ItemsApi";

import 'style/items/subBarcodes.css';
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import Checkbox from 'components/tools/Checkbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import { useSelector } from "react-redux";
/* #endregion Imports */

const SubBarcodesComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "subBarcodesForm";
    const isActive = useRef(false);
    const settings = useSelector(state => state.main.settings);

    const subBarcodeTB = useRef();
    const isCrtnCheckbox = useRef();
    const searchTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalSubBarcodes, setTotalSubBarcodes] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const itemId = useRef(0);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Sub Barcode', row: "subBarcode", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if(tableRef.current !== null)
            tableRef.current.setLoading(true);
        
        apiLoadSubBarcodes(itemId.current).then((result) => {
            try {
                setTotalSubBarcodes(result.total.totalSubBarcodes);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);   
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;
    
        const isValid = await validation();
        
        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateSubBarcode(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Sub-Barcode updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSubBarcode(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Sub-Barcode saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }else{
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSubBarcode(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;
        
                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
        if (window.confirm("Are you sure to delete this SubBarcode?")) {
                apiDeleteSubBarcode(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Sub-Barcode deleted successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this SubBarcode?")) {
                apiDeleteSubBarcode(updateId.current)
                    .then((result) => {
                        toast.current.show("Sub-Barcode deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        subBarcodeTB.current.setText("");
        isCrtnCheckbox.current.setChecked(false);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        subBarcodeTB.current.focus();
    }
    const setFormData = (subBarcode) => {
        formData.current = subBarcode;

        subBarcodeTB.current.setText(subBarcode.subBarcode);
        isCrtnCheckbox.current.setChecked(subBarcode.isCrtn);

        subBarcodeTB.current.focus();
    }
    const getFormData = () => {
        const item = {
            itemId:itemId.current,
            subBarcode: subBarcodeTB.current.getText().trim(),
            isCrtn: isCrtnCheckbox.current.isChecked(),
        }

        return item;
    }
    const validation = async () => {
        const barcode = subBarcodeTB.current.getText().trim();
        const isValid = await isValidBarcode(barcode);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidBarcode = async (barcode) => {
        if (barcode === '') {
            toast.current.show("Please Enter Sub Barcode.", "i");
            subBarcodeTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (barcode.toLowerCase() === formData.current.subBarcode.toLowerCase())
                    return true;
                else
                    return isBarcodeExists(barcode);
            }
            else
                return isBarcodeExists(barcode);
        }
    }
    const isBarcodeExists = async (barcode) => {
        const result = await apiIsBarcodeExists(barcode);
        if (result.isExists) {
            toast.current.show("Barcode already exists.", "i");
            subBarcodeTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (props.mainActiveTab !== "items")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        }else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        } else if (e.altKey && e.key.toLowerCase() === "c") {
            e.preventDefault();
            changeCrtnCheckbox();
        }
    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onBarcodeTBKeyDown = (e) => {
        if (e.key.toLowerCase() === "enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const changeCrtnCheckbox = () => {
        isCrtnCheckbox.current.setChecked(!isCrtnCheckbox.current.isChecked());
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch){
            // loadData();
        }
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        itemId.current = props.itemId;
        loadData();
        
        subBarcodeTB.current.focus();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none', overflow: 'auto' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow:'auto' }}>

                        <Textbox
                            ref={subBarcodeTB}
                            label="Sub Barcode"
                            tabIndex={20}
                            onKeyDown={onBarcodeTBKeyDown}
                            style={{}} />

                        <Checkbox
                            ref={isCrtnCheckbox}
                            label="Carton Barcode"/>

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onChange={onSearchTBChange}
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={21} />

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            myTable='subBarcodesTable'
                            isActive={isActive}
                            onDoubleClick={onEditBtnClick}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Sub-Barcodes: </b>{totalSubBarcodes}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default SubBarcodesComponent;