import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import 'style/sales/bills/salesBillThermalUrdu.css';
import parse from 'html-react-parser';
import { toDecimal } from "utilities/Utils";
import ReportThermalStyle from "components/reports/styles/ReportThermalStyle";

const ThermalUrdu = forwardRef((props, ref) => {
    const data = props.data;
    const billsCode = useSelector(state => state.main.billsCode);
    const bill = billsCode.salesThermalUrdu;

    return (
        <>
            <div className="salesBillThermalUrdu reportThermal" ref={ref}>
                <ReportThermalStyle />
                {
                    !bill && <div>
                        <div id="salesBillData">
                            Nothing to print
                        </div>
                    </div>
                }

                {
                    bill &&
                    <div>
                        {/* Style */}
                        <div>
                            {parse(bill.style)}
                        </div>

                        {/* Header */}
                        <div className="header">
                            {parse(bill.header)}
                        </div>

                        {/* Body */}
                        <div className="body">
                            {parse(bill.body)}

                            {
                                data.length > 0 &&
                                <div>
                                    <div className="header1">
                                        <div className={`customerName ${data[0].customerName !== "" ? "d-block" : "d-none"}`}>
                                            <p className="tac urdu" style={{ fontSize: '16px' }}>{data[0].customerName}</p>
                                        </div>
                                        <div className={`billType ${data[0].billType !== "Original" ? "d-block" : "d-none"}`}>
                                            <p className="tac">{data[0].billType}</p>
                                        </div>
                                    </div>

                                    <div className="header1">
                                        <div className="dRow jcsb">
                                            <p className="date">Date: {data[0].date}</p>
                                            <p className="cashier">Cashier: {data[0].user}</p>
                                        </div>
                                        <div className="dRow jcsb">
                                            <p className="time">Time: {data[0].time}</p>
                                            <p className="salesOn">{data[0].salesOn}</p>
                                            <p className="invoiceNo">Inv #: {data[0].id}</p>
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div id="salesBillData">

                                        <table>
                                            <thead>
                                                <tr className="urdu">
                                                    <th>ٹوٹل</th>
                                                    {bill.showTotalDisc === true && <th>ڈسکاؤنٹ</th>}
                                                    {bill.showDiscPercent === true && <th>ڈسکاؤنٹ</th>}
                                                    {bill.showDisc === true && <th>ڈسکاؤنٹ</th>}
                                                    {data[0].saleCartons === true && <th>قیمت کارٹن</th>}
                                                    <th>قیمت</th>
                                                    <th>تعداد</th>
                                                    {data[0].saleCartons === true && <th>کارٹن</th>}
                                                    <th style={{ textAlign: 'right' }}>تفصیل سامان</th>
                                                    {bill.showSr === true && <th>سیریل</th>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td className="borderLeft total">{toDecimal(row["total"], 0)}</td>
                                                            {bill.showTotalDisc === true && <td className="borderLeft totalDisc">{toDecimal(row["totalDisc"])}</td>}
                                                            {bill.showDiscPercent === true && <td className="borderLeft discPercent">{`${toDecimal(row["disc"])}%`}</td>}
                                                            {bill.showDisc === true && <td className="borderLeft disc">{toDecimal(row["disc"])}</td>}
                                                            {data[0].saleCartons === true && <td className="borderLeft crtnRate">{row["crtn"] > 0 ? toDecimal(row["crtnRate"]) : 0}</td>}
                                                            <td className="borderLeft rate">{row["qty"] > 0 ? toDecimal(row["rate"]) : 0}</td>
                                                            <td className={`borderLeft qty ${row["qty"] < 0 ? "returnBill" : ""}`}>{row["qty"]}</td>
                                                            {data[0].saleCartons === true && <td className={`borderLeft crtn ${row["crtn"] < 0 ? "returnBill" : ""}`}>{row["crtn"]}</td>}
                                                            <td className={`itemname urdu borderLeft`}>{row["urduname"]}</td>
                                                            {bill.showSr === true && <td className="borderLeft sr">{rowIndex + 1}</td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Footer 1 */}
                                    <div className={`footer1`}>
                                        <div className="dRow jcsb" style={{ border: '1px solid black' }}>
                                            <div className="dCol" style={{ minWidth: '160px', border: '1px solid black', alignItems: 'start' }}>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                    <p className="heading">سب ٹوٹل</p>
                                                </div>

                                                <div className={`dRow ${data[0].grandTotalDisc > 0 ? "d-flex" : 'd-none'}`} style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].grandTotalDisc)}</p>
                                                    <p className="heading">ڈسکاؤنٹ</p>
                                                </div>
                                            </div>
                                            <div className="dCol" style={{ width: '100%' }}>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.length}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '14px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل آئٹمز</p>
                                                    </div>
                                                </div>
                                                <div className="dRow sumQty d-none" style={{ width: '100%' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.reduce((sum, { qty }) => sum + qty, 0)}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '14px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل تعداد</p>
                                                    </div>
                                                </div>
                                                <div className="dRow sumCrtn d-none" style={{ width: '100%' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.reduce((sum, { crtn }) => sum + crtn, 0)}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '14px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل کارٹن</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dRow jcsb">
                                            <div className={`dCol ${data[0].salesOn === "Cash" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', border: '1px solid black', alignItems: 'end' }}>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].grandTotal)}</p>
                                                    <p className="heading">ٹوٹل</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].payment)}</p>
                                                    <p className="heading">وصولی</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].change)}</p>
                                                    <p className="heading">بقایا</p>
                                                </div>
                                            </div>

                                            <div className={`dCol ${data[0].salesOn === "Credit" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', border: '1px solid black', alignItems: 'end' }}>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].grandTotal)}</p>
                                                    <p className="heading">ٹوٹل</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label underline">{toDecimal(data[0].oldBalance)}</p>
                                                    <p className="heading">سابقہ</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].grandTotal + data[0].oldBalance)}</p>
                                                    <p className="heading">ٹوٹل</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label underline">{toDecimal(data[0].payment)}</p>
                                                    <p className="heading">وصولی</p>
                                                </div>
                                                <div className="dRow" style={{ width: '100%' }}>
                                                    <p className="label">{toDecimal(data[0].newBalance)}</p>
                                                    <p className="heading">نیا بیلنس</p>
                                                </div>
                                            </div>

                                            <div className="dRow jcc" style={{ width: '100%', alignSelf: 'center' }}>
                                                <p className="urdu" style={{ maxWidth: '80px', textAlign: 'center', fontSize: '16px' }}>آپکی تشریف آوری کا شکریہ</p>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Barcode */}
                                    <div className="barcodePanel d-none">
                                        <div className="dRow jcc barcodeBox">
                                            <p className="barcode">{`(${data[0].id})`}</p>
                                        </div>
                                        <div className="dRow jcc">
                                            <p style={{ fontSize: '12px', marginTop: '-4px' }}>{`(${data[0].id})`}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Footer */}
                        <div className="footer">
                            {parse(bill.footer)}
                        </div>

                    </div>
                }

            </div>
        </>
    )
});

export default ThermalUrdu;