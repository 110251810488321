/* #region Imports */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import {
    apiLoadLinkedItems,
    apiInsertLinkedItem,
    apiUpdateLinkedItem,
    apiDeleteLinkedItem,
    apiGetLinkedItem,
    apiIsLinkedItemExists
} from "api/items/LinkedItemsApi";

import 'style/items/linkedItems.css';
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import Checkbox from 'components/tools/Checkbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import AutocompleteTextbox from "components/tools/AutocompleteTextbox";
import { useSelector } from "react-redux";
/* #endregion Imports */

const LinkedItemsComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "linkedItemsForm";
    const isActive = useRef(false);
    const settings = useSelector(state => state.main.settings);

    const barcodeTB = useRef();
    const updateCostCheckbox = useRef();
    const updateRetailCheckbox = useRef();
    const updateWholesaleCheckbox = useRef();
    const updateCrtnRateCheckbox = useRef();
    const updateCrtnSizeCheckbox = useRef();
    const updateExpiryCheckbox = useRef();
    const rateFormulaTB = useRef();

    const searchTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalLinkedItems, setTotalLinkedItems] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const mainItem = useRef(null);
    const linkedItemId = useRef(0);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadLinkedItems({
            itemId: Number(mainItem.current.id),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalLinkedItems(result.total.totalLinkedItems);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;

                apiUpdateLinkedItem(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Linked Item updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertLinkedItem(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Linked Item saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetLinkedItem(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Linked Item?")) {
                apiDeleteLinkedItem(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Linked Item deleted successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Linked Item?")) {
                apiDeleteLinkedItem(updateId.current)
                    .then((result) => {
                        toast.current.show("Linked Item deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        barcodeTB.current.setText("");
        disableCheckboxes();
        rateFormulaTB.current.setText("");

        updateId.current = 0;
        isUpdate.current = false;
        linkedItemId.current = 0;
        formData.current = null;

        barcodeTB.current.focus();
    }
    const setFormData = (linkedItem) => {
        enableOrDisableCheckboxes(Number(linkedItem.crtnSize));

        formData.current = linkedItem;

        linkedItemId.current = linkedItem.linkedItemId;
        barcodeTB.current.setText(linkedItem.itemname);
        updateCostCheckbox.current.setChecked(linkedItem.updateCost);
        updateRetailCheckbox.current.setChecked(linkedItem.updateRetail);
        updateWholesaleCheckbox.current.setChecked(linkedItem.updateWholesale);
        updateCrtnRateCheckbox.current.setChecked(linkedItem.updateCrtnRate);
        updateCrtnSizeCheckbox.current.setChecked(linkedItem.updateCrtnSize);
        updateExpiryCheckbox.current.setChecked(linkedItem.updateExpiry);
        rateFormulaTB.current.setText(linkedItem.rateFormula);

        rateFormulaTB.current.focus();
    }
    const getFormData = () => {
        const item = {
            itemId: Number(mainItem.current.id),
            linkedItemId: linkedItemId.current,
            updateCost: updateCostCheckbox.current.isChecked(),
            updateRetail: updateRetailCheckbox.current.isChecked(),
            updateWholesale: updateWholesaleCheckbox.current.isChecked(),
            updateCrtnRate: updateCrtnRateCheckbox.current.isChecked(),
            updateCrtnSize: updateCrtnSizeCheckbox.current.isChecked(),
            updateExpiry: updateExpiryCheckbox.current.isChecked(),
            rateFormula: rateFormulaTB.current.getText()
        }

        return item;
    }
    const enableOrDisableCheckboxes = (linkedItemCrtnSize) => {
        const mainCrtnSize = Number(mainItem.current.crtnSize);
        // const linkedItemCrtnSize = Number(linkedItem.crtnSize);

        if (linkedItemCrtnSize === 0) {
            if (mainCrtnSize === 0 || mainCrtnSize > 1) {
                updateCostCheckbox.current.enable();
                updateRetailCheckbox.current.enable();
                updateWholesaleCheckbox.current.enable();

                updateCostCheckbox.current.setChecked(true);
                updateRetailCheckbox.current.setChecked(true);
                updateWholesaleCheckbox.current.setChecked(true);
            }
        }
        else if (linkedItemCrtnSize === 1) {
            if (mainCrtnSize === 1 || mainCrtnSize > 1) {
                updateCrtnRateCheckbox.current.enable();
                updateCrtnRateCheckbox.current.setChecked(true);
            }

            if (mainCrtnSize === 1) {
                updateCostCheckbox.current.enable();
                updateCostCheckbox.current.setChecked(true);
            }
        }
        else if (linkedItemCrtnSize > 1) {
            if (mainCrtnSize > 1) {
                updateCostCheckbox.current.enable();
                updateRetailCheckbox.current.enable();
                updateWholesaleCheckbox.current.enable();
                updateCrtnRateCheckbox.current.enable();
                updateCrtnSizeCheckbox.current.enable();

                updateCostCheckbox.current.setChecked(true);
                updateRetailCheckbox.current.setChecked(true);
                updateWholesaleCheckbox.current.setChecked(true);
                updateCrtnRateCheckbox.current.setChecked(true);
                updateCrtnSizeCheckbox.current.setChecked(true);
            }
        }
    }
    const showDataIntoTextboxes = (linkedItem) => {
        linkedItemId.current = Number(linkedItem.id);
        barcodeTB.current.setText(linkedItem.itemname);
        rateFormulaTB.current.focus();

        disableCheckboxes();
        enableOrDisableCheckboxes(Number(linkedItem.crtnSize));
    }
    const disableCheckboxes = () => {
        updateCostCheckbox.current.disable();
        updateRetailCheckbox.current.disable();
        updateWholesaleCheckbox.current.disable();
        updateCrtnRateCheckbox.current.disable();
        updateCrtnSizeCheckbox.current.disable();

        updateCostCheckbox.current.setChecked(false);
        updateRetailCheckbox.current.setChecked(false);
        updateWholesaleCheckbox.current.setChecked(false);
        updateCrtnRateCheckbox.current.setChecked(false);
        updateCrtnSizeCheckbox.current.setChecked(false);
    }
    const validation = async () => {
        if (linkedItemId.current === 0) {
            toast.current.show("Please select Item.", "i");
            barcodeTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (Number(formData.current.linkedItemId) === Number(linkedItemId.current))
                    return true;
                else
                    return isLinkedItemExists();
            }
            else
                return isLinkedItemExists();
        }
    }
    const isLinkedItemExists = async () => {
        const result = await apiIsLinkedItemExists({
            itemId: Number(mainItem.current.id),
            linkedItemId: linkedItemId.current,
        });
        if (result.isExists) {
            toast.current.show("Item already exists.", "i");
            barcodeTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (props.mainActiveTab !== "items")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Last Textbox Keydown
    const onLastTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    // Textboxes Keydown
    const onBarcodeTBKeyDown = (data) => {
        showDataIntoTextboxes(data);
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        mainItem.current = props.item;
        loadData();
        // clearTextboxes();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none', overflow: 'auto' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <AutocompleteTextbox
                            ref={barcodeTB}
                            label="Select Item"
                            tabIndex={22}
                            onEnter={onBarcodeTBKeyDown}
                            style={{}} />

                        <div className="" style={{ maxWidth: '400px' }}>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    ref={updateCostCheckbox}
                                    label="Cost"
                                    style={{ flex: '1' }} />

                                <Checkbox
                                    ref={updateRetailCheckbox}
                                    label="Retail"
                                    className="defaultMarginLeft"
                                    style={{ flex: '1' }} />

                                <Checkbox
                                    ref={updateWholesaleCheckbox}
                                    label="Wholesale"
                                    className="defaultMarginLeft"
                                    style={{ flex: '1' }} />
                            </div>

                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    ref={updateCrtnRateCheckbox}
                                    label="Carton Rate"
                                    style={{ flex: '1' }} />

                                <Checkbox
                                    ref={updateCrtnSizeCheckbox}
                                    label="Carton Size"
                                    className="defaultMarginLeft"
                                    style={{ flex: '1' }} />

                                <Checkbox
                                    ref={updateExpiryCheckbox}
                                    label="Expiry"
                                    checked={true}
                                    className="defaultMarginLeft"
                                    style={{ flex: '1' }} />
                            </div>

                        </div>

                        <Textbox
                            ref={rateFormulaTB}
                            label="Rate Formula"
                            onKeyDown={onLastTBKeydown}
                            tabIndex={23} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onChange={onSearchTBChange}
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={24} />

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            myTable='linkedItemsTable'
                            isActive={isActive}
                            onDoubleClick={onEditBtnClick}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Linked Items: </b>{totalLinkedItems}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default LinkedItemsComponent;