/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/accounts/customers.css';
import { mainReducerLoadAccountCategories, mainReducerLoadCustomers } from 'state/reducers/mainReducer';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadCustomers,
    apiInsertCustomer,
    apiUpdateCustomer,
    apiDeleteCustomer,
    apiGetCustomer,
    apiIsCustomerExists,
    apiUploadImage as apiUploadCustomerImage
} from "api/accounts/CustomersApi";
import { convertToInt, convertToNumber, isFileSizeValid, selectNextElement } from "utilities/Utils";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ComboBox from 'components/tools/ComboBox';
import TextboxCB from 'components/tools/TextboxCB';
import AccountCategoryCB from 'components/tools/dropdowns/AccountCategoryCB';
import Checkbox from 'components/tools/Checkbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import Image from "components/tools/Image";
import Pagination from "components/tools/Pagination";
import AccountCategoriesComponent from 'components/accounts/AccountCategoriesComponent';
import { getClientId } from 'utilities/DB';
/* #endregion Imports */

const CustomersComponent = (props) => {

    /* #region Variables */
    const formId = "customersForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.main.settings);
    const passwords = useSelector(state => state.main.passwords);

    const customerNameTB = useRef();
    const contactTB = useRef();
    const emailTB = useRef();
    const cityTB = useRef();
    const addressTB = useRef();
    const openingBalanceTB = useRef();
    const dueDaysTB = useRef();
    const discTB = useRef();
    const isRetailCheckbox = useRef();
    const categoryCB = useRef();

    const searchTB = useRef();
    const searchCategoryCB = useRef();
    const searchTypeCB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const paginationRef = useRef();
    const [totalCustomers, setTotalCustomers] = useState(0);

    // Modals
    const accountCategoriesFormRef = useRef(null);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Customer Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Contact', row: "contact", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Email', row: "email", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'City', row: "city", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Address', row: "address", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();

        customerNameTB.current.focus();
    }
    const loadData = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadCustomers({
            page: page,
            itemsPerPage: itemsPerPage,
            categoryId: searchCategoryCB.current.getValue(),
            searchType: searchTypeCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalCustomers(result.total.totalCustomers);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const loadCustomersCB = (result) => {
        dispatch(mainReducerLoadCustomers(result));
        // apiLoadMainCustomers().then((result) => {
        //     dispatch(mainReducerLoadCustomers(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const loadAccountCategoriesCB = (result) => {
        dispatch(mainReducerLoadAccountCategories(result));
        categoryCB.current.setValue(0);
        // apiLoadAccountCategories().then((result) => {
        //     dispatch(mainReducerLoadAccountCategories(result));
        //     categoryCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateCustomer(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Customer updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadCustomersCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertCustomer(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Customer saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadCustomersCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetCustomer(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteCustomer(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Customer deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadCustomersCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Customer?")) {
                    apiDeleteCustomer(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Customer deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadCustomersCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteCustomer(updateId.current)
                            .then((result) => {
                                toast.current.show("Customer deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadCustomersCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Customer?")) {
                    apiDeleteCustomer(updateId.current)
                        .then((result) => {
                            toast.current.show("Customer deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadCustomersCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxes = () => {
        customerNameTB.current.setText("");
        contactTB.current.setText("");
        emailTB.current.setText("");
        cityTB.current.setText("");
        addressTB.current.setText("");
        openingBalanceTB.current.setText("");
        discTB.current.setText("");
        discTB.current.setCBValue(true ? 0 : 1);
        isRetailCheckbox.current.setChecked(false);
        categoryCB.current.setValue(0);
        dueDaysTB.current.setText("");

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        customerNameTB.current.focus();

        document.getElementById("customerImageDialog").value = "";
        document.getElementById("customerImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (customer) => {
        formData.current = customer;

        customerNameTB.current.setText(customer.accountName);
        contactTB.current.setText(customer.contact);
        emailTB.current.setText(customer.email);
        cityTB.current.setText(customer.city);
        addressTB.current.setText(customer.address);

        discTB.current.setText(customer.disc);
        discTB.current.setCBValue(customer.isDiscRsPer ? 1 : 0);

        dueDaysTB.current.setText(customer.dueDays);
        isRetailCheckbox.current.setChecked(customer.isRetail);
        categoryCB.current.setValue(customer.categoryId);

        customerNameTB.current.focus();

        if (customer.imageUrl !== '')
            document.getElementById("customerImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${customer.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("customerImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const customer = {
            accountName: customerNameTB.current.getText().trim(),
            contact: contactTB.current.getText(),
            email: emailTB.current.getText(),
            city: cityTB.current.getText(),
            address: addressTB.current.getText(),
            openingBalance: convertToNumber(openingBalanceTB.current.getText()),
            disc: convertToNumber(discTB.current.getText()),
            isDiscRsPer: discTB.current.getCBValue() === 1 ? true : false,
            isRetail: isRetailCheckbox.current.isChecked(),
            categoryId: categoryCB.current.getValue(),
            dueDays: convertToInt(dueDaysTB.current.getText()),
        }

        return customer;
    }
    const validation = async () => {
        const customerName = customerNameTB.current.getText().trim();
        const isValid = await isValidCustomer(customerName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidCustomer = async (customerName) => {
        if (customerName === '') {
            toast.current.show("Please Enter Customer Name.", "i");
            customerNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (customerName.toLowerCase() === formData.current.accountName.toLowerCase())
                    return true;
                else
                    return isCustomerExists(customerName);
            }
            else
                return isCustomerExists(customerName);
        }
    }
    const isCustomerExists = async (customerName) => {
        const result = await apiIsCustomerExists(customerName);
        if (result.isExists) {
            toast.current.show("Customer Name already exists.", "i");
            customerNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onCustomerImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadCustomerImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("customerImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    const showCustomerForm = () => {
        accountCategoriesFormRef.current.show();
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCategoryBtnClick = () => {
        showCustomerForm();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    const onCustomerUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (activeTab.current !== "customers")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSearchCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "customers" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "customers" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <AccountCategoriesComponent
                ref={accountCategoriesFormRef}
                messageBox={messageBox}
                toast={toast}
                isActive={isActive}
                loadAccountCategoriesCB={loadAccountCategoriesCB} />

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div className='top'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='panel'>
                            <Textbox
                                ref={customerNameTB}
                                label="Customer Name"
                                tabIndex={401}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={contactTB}
                                label="Contact"
                                tabIndex={402}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={emailTB}
                                label="Email"
                                tabIndex={403}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={cityTB}
                                label="City"
                                tabIndex={404}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textarea
                                ref={addressTB}
                                label="Address"
                                tabIndex={405}
                                onKeyDown={onTextboxesKeyDown} />

                        </div>

                        <div className="panel defaultMarginLeft">

                            <div className='imagePanel'>
                                <div style={{ flex: 2, marginRight: '20px', minWidth: '200px' }}>
                                    <Textbox
                                        ref={openingBalanceTB}
                                        label="Opening Balance"
                                        tabIndex={406}
                                        onKeyDown={onTextboxesKeyDown} />

                                    <TextboxCB
                                        ref={discTB}
                                        label="Discount"
                                        tabIndex={407}
                                        onKeyDown={onTextboxesKeyDown}
                                        data={[{ id: 0, name: '%' }, { id: 1, name: 'Rs' }]} />
                                    <Textbox
                                        ref={dueDaysTB}
                                        label="Due Days"
                                        tabIndex={408}
                                        onKeyDown={onLastTextboxKeyDown} />
                                    <AccountCategoryCB
                                        ref={categoryCB} />

                                    <div className={`${settings.fourRateSystem ? "d-none" : "d-block"}`}>
                                        <Checkbox
                                            ref={isRetailCheckbox}
                                            label="Retail" />
                                    </div>

                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                                    <Image
                                        width={180}
                                        height={136}
                                        id="customerImageId"
                                        imageId="customerImage"
                                        imageDialogId="customerImageDialog"
                                        onUploadBtnClick={(e) => {
                                            onCustomerUploadBtnClick(e);
                                        }}
                                        onUpload={(e) => {
                                            onCustomerImageUpload(e);
                                        }}
                                    />
                                </div>

                            </div>

                            <div>

                            </div>
                        </div>

                    </div>

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        <ButtonIcon
                            label="Category"
                            icon="icons/buttons/categories.png"
                            onClick={onCategoryBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onCancelBtnClick} />
                    </div>

                    <div className="searchContainer" style={{ display: 'flex' }}>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onChange={onSearchTBChange}
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={409} />

                        <AccountCategoryCB
                            ref={searchCategoryCB}
                            onChange={onSearchCBChange}
                            style={{ maxWidth: '350px' }}
                            className="defaultMarginLeft" />

                        <ComboBox
                            ref={searchTypeCB}
                            label="Type"
                            onChange={onSearchCBChange}
                            data={[
                                { id: 0, name: 'Both' },
                                { id: 1, name: 'Retail' },
                                { id: 2, name: 'Wholesale' },
                            ]}
                            style={{ maxWidth: '250px' }}
                            className="defaultMarginLeft" />

                    </div>
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='customersTable'
                    // autoSelectFirstRow={true}
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="paginationFooter">
                    <p><b>Total Customers: </b>{totalCustomers}</p>
                    <Pagination
                        ref={paginationRef}
                        totalItems={totalCustomers}
                        reload={loadData}
                        showTotalLabel={false}
                        totalLabel="Total Customers: " />
                </div>

            </div>
        </>
    )
}

export default CustomersComponent;