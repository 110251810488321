import { post, getUserId } from 'utilities/DB.js';

export const apiInsertSales = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/insertSales", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSales = (params) => {
    return new Promise((resolve, reject) => {
        post("api/sales/updateSales", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSales = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/deleteSales", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSales = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/getSales", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiTempClose = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/tempClose", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetNextId = () => {
    const params = {
        userId: getUserId()
    }
    return new Promise((resolve, reject) => {
        post("api/sales/getNextId", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsReplaceExists = (vendorId) => {
    const params = {
        vendorId: vendorId
    }
    return new Promise((resolve, reject) => {
        post("api/sales/isReplaceExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBill = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/getBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBill = (params) => {
    return new Promise((resolve, reject) => {
        post("api/sales/updateBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPendingBills = () => {
    const params = {
        userId: getUserId()
    }
    
    return new Promise((resolve, reject) => {
        post("api/sales/loadPendingBills", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPendingBillItems = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/loadPendingBillItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPostedBills = (params) => {
    return new Promise((resolve, reject) => {
        post("api/sales/loadPostedBills", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPostedBillItems = (params) => {
    // params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/sales/loadPostedBillItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
