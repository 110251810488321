/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/accounts/suppliers.css';
import { mainReducerLoadAccountCategories, mainReducerLoadSuppliers } from 'state/reducers/mainReducer';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadSuppliers,
    apiInsertSupplier,
    apiUpdateSupplier,
    apiDeleteSupplier,
    apiGetSupplier,
    apiIsSupplierExists,
    apiUploadImage as apiUploadSupplierImage
} from "api/accounts/SuppliersApi";
import { isFileSizeValid, selectNextElement } from "utilities/Utils";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import AccountCategoryCB from 'components/tools/dropdowns/AccountCategoryCB';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import Image from "components/tools/Image";
import Pagination from "components/tools/Pagination";
import AccountCategoriesComponent from 'components/accounts/AccountCategoriesComponent';
import { getClientId } from 'utilities/DB';
/* #endregion Imports */

const SuppliersComponent = (props) => {

    /* #region Variables */
    const formId = "suppliersForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.main.settings);
    const passwords = useSelector(state => state.main.passwords);

    const supplierNameTB = useRef();
    const contactTB = useRef();
    const cityTB = useRef();
    const addressTB = useRef();
    const categoryCB = useRef();

    const searchTB = useRef();
    const searchCategoryCB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const paginationRef = useRef();
    const [totalSuppliers, setTotalSuppliers] = useState(0);

    // Modals
    const accountCategoriesFormRef = useRef(null);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Supplier Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Contact', row: "contact", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Email', row: "email", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'City', row: "city", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Address', row: "address", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();

        supplierNameTB.current.focus();
    }
    const loadData = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadSuppliers({
            page: page,
            itemsPerPage: itemsPerPage,
            categoryId: searchCategoryCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalSuppliers(result.total.totalSuppliers);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const loadSuppliersCB = (result) => {
        dispatch(mainReducerLoadSuppliers(result));
        // apiLoadMainSuppliers().then((result) => {
        //     dispatch(mainReducerLoadSuppliers(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const loadAccountCategoriesCB = (result) => {
        dispatch(mainReducerLoadAccountCategories(result));
        // apiLoadAccountCategories().then((result) => {
        //     dispatch(mainReducerLoadAccountCategories(result));
        //     categoryCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateSupplier(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Supplier updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadSuppliersCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSupplier(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Supplier saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadSuppliersCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSupplier(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSupplier(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Supplier deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadSuppliersCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Supplier?")) {
                    apiDeleteSupplier(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Supplier deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadSuppliersCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSupplier(updateId.current)
                            .then((result) => {
                                toast.current.show("Supplier deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadSuppliersCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Supplier?")) {
                    apiDeleteSupplier(updateId.current)
                        .then((result) => {
                            toast.current.show("Supplier deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadSuppliersCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxes = () => {
        supplierNameTB.current.setText("");
        contactTB.current.setText("");
        cityTB.current.setText("");
        addressTB.current.setText("");
        categoryCB.current.setValue(0);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        supplierNameTB.current.focus();

        document.getElementById("supplierImageDialog").value = "";
        document.getElementById("supplierImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (supplier) => {
        formData.current = supplier;

        supplierNameTB.current.setText(supplier.accountName);
        contactTB.current.setText(supplier.contact);
        cityTB.current.setText(supplier.city);
        addressTB.current.setText(supplier.address);

        categoryCB.current.setValue(supplier.categoryId);

        supplierNameTB.current.focus();

        if (supplier.imageUrl !== '')
            document.getElementById("supplierImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${supplier.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("supplierImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const supplier = {
            accountName: supplierNameTB.current.getText().trim(),
            contact: contactTB.current.getText(),
            city: cityTB.current.getText(),
            address: addressTB.current.getText(),
            categoryId: categoryCB.current.getValue(),
        }

        return supplier;
    }
    const validation = async () => {
        const supplierName = supplierNameTB.current.getText().trim();
        const isValid = await isValidSupplier(supplierName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidSupplier = async (supplierName) => {
        if (supplierName === '') {
            toast.current.show("Please Enter Supplier Name.", "i");
            supplierNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (supplierName.toLowerCase() === formData.current.accountName.toLowerCase())
                    return true;
                else
                    return isSupplierExists(supplierName);
            }
            else
                return isSupplierExists(supplierName);
        }
    }
    const isSupplierExists = async (supplierName) => {
        const result = await apiIsSupplierExists(supplierName);
        if (result.isExists) {
            toast.current.show("Supplier Name already exists.", "i");
            supplierNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onSupplierImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if (imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)) {
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadSupplierImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("supplierImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    const onSupplierUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (activeTab.current !== "suppliers")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSearchCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    const onSearchTBChange = () => {
        if (settings.instantSearch)
            loadData();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "suppliers" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "suppliers" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <AccountCategoriesComponent
                ref={accountCategoriesFormRef}
                messageBox={messageBox}
                toast={toast}
                isActive={isActive}
                loadAccountCategoriesCB={loadAccountCategoriesCB} />

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div className='top'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='panel'>
                            <Textbox
                                ref={supplierNameTB}
                                label="Supplier Name"
                                tabIndex={801}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={contactTB}
                                label="Contact"
                                tabIndex={802}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={cityTB}
                                label="City"
                                tabIndex={803}
                                onKeyDown={onTextboxesKeyDown} />

                        </div>

                        <div className="panel defaultMarginLeft">
                            <div className='imagePanel'>
                                <div style={{ flex: 2, marginRight: '20px', minWidth: '200px' }}>

                                    <Textarea
                                        ref={addressTB}
                                        label="Address"
                                        tabIndex={804}
                                        style={{ height: '120px !important' }}
                                        onKeyDown={onLastTextboxKeyDown} />

                                    <div className='d-none'>
                                        <AccountCategoryCB
                                            ref={categoryCB} />
                                    </div>

                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                                    <Image
                                        width={180}
                                        height={136}
                                        id="supplierImageId"
                                        imageId="supplierImage"
                                        imageDialogId="supplierImageDialog"
                                        onUploadBtnClick={(e) => {
                                            onSupplierUploadBtnClick(e);
                                        }}
                                        onUpload={(e) => {
                                            onSupplierImageUpload(e);
                                        }}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        {/* <ButtonIcon
                            label="Category"
                            icon="icons/buttons/categories.png"
                            onClick={onCategoryBtnClick} /> */}

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onCancelBtnClick} />
                    </div>

                    <div className="searchContainer" style={{ display: 'flex' }}>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onChange={onSearchTBChange}
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={805} />

                        <AccountCategoryCB
                            ref={searchCategoryCB}
                            onChange={onSearchCBChange}
                            style={{ maxWidth: '350px', display: 'none' }}
                            className="defaultMarginLeft" />

                    </div>
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='suppliersTable'
                    // autoSelectFirstRow={true}
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="paginationFooter">
                    <p><b>Total Suppliers: </b>{totalSuppliers}</p>
                    <Pagination
                        ref={paginationRef}
                        totalItems={totalSuppliers}
                        reload={loadData}
                        showTotalLabel={false}
                        totalLabel="Total Suppliers: " />
                </div>

            </div>
        </>
    )
}

export default SuppliersComponent;