/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/purchase/rejectedItems.css';
import Table from 'components/tools/Table';
import ButtonIcon from 'components/tools/ButtonIcon';
import { convertToInt, convertToNumber, selectNextElement } from 'utilities/Utils';
import { apiDeleteRejectedItem, apiGetRejectedItem, apiInsertRejectedItem, apiLoadRejectedItems, apiUpdateRejectedItem } from 'api/purchase/RejectedItemsApi';
import AutocompleteTextbox from 'components/tools/AutocompleteTextbox';
import Textbox from 'components/tools/Textbox';
import VendorCB from 'components/tools/dropdowns/VendorCB';
import { useSelector } from 'react-redux';
/* #endregion */

const RejectedItemsComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "rejectedItemsForm";
    const isActive = useRef(false);
    const settings = useSelector(state => state.main.settings);

    const itemnameTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const itemId = useRef();
    const vendorCB = useRef();

    const searchTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalRejectedItems, setTotalRejectedItems] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '300px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadRejectedItems({
            vendorId: vendorCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                if (isActive.current) {
                    setTotalRejectedItems(result.total.totalRejectedItems);
                    tableRef.current.setData([...result.rows]);
                    tableRef.current.setLoading(false);
                }
            } catch (error) {
                if (isActive.current) {
                    tableRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateRejectedItem(data)
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            toast.current.show("Item updated successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            } else {
                apiInsertRejectedItem(getFormData())
                    .then((result) => {
                        if (isActive.current) {
                            inserted.current = true;
                            toast.current.show("Item saved successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        if (isActive.current) {
                            inserted.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        }
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetRejectedItem(tempId)
                .then((result) => {
                    if (isActive.current) {
                        updateId.current = tempId;
                        isUpdate.current = true;

                        setFormData(result);
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        messageBox.current.show(err.message, "Error", "e");
                    }
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Supplier?")) {
                apiDeleteRejectedItem(Number(row['id']))
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("Item deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    };
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Supplier?")) {
                apiDeleteRejectedItem(updateId.current)
                    .then((result) => {
                        if (isActive.current) {
                            toast.current.show("Item deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                        }
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        itemnameTB.current.setText("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        itemnameTB.current.focus();
    }
    const setFormData = (item) => {
        formData.current = item;

        itemId.current = item.itemId;
        itemnameTB.current.setText(item.itemname);
        qtyTB.current.setText(item.qty);
        crtnTB.current.setText(item.crtn);

        qtyTB.current.focus();
    }
    const getFormData = () => {
        const item = {
            itemId: itemId.current,
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToInt(crtnTB.current.getText()),
        }

        return item;
    }
    const validation = () => {
        if (itemId.current === 0) {
            toast.current.show("Please Select Item", "i");
            return false;
        }

        if (convertToNumber(qtyTB.current.getText()) === 0 && convertToNumber(crtnTB.current.getText()) === 0) {
            toast.current.show("Please Enter Quantity", "i");
            return false;
        }

        return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }
    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (props.mainActiveTab !== "purchase")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    const onBarcodeTBKeyDown = (item) => {
        itemnameTB.current.setText(item.itemname);
        itemId.current = Number(item.id);

        qtyTB.current.focus();
    }
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onItemnameChange = () => {
        if (itemnameTB.current.getText() === '') {
            itemId.current = 0;
        }
    }
    const onSearchTBChange = () => {
        if(settings.instantSearch)
            loadData();
    }
    
    /* #endregion*/

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <AutocompleteTextbox
                            ref={itemnameTB}
                            label="Item"
                            onEnter={onBarcodeTBKeyDown}
                            onChange={onItemnameChange}
                            tabIndex={1001}
                            style={{ width: '100%' }} />

                        <div className="dRow">
                            <Textbox
                                ref={qtyTB}
                                label="Qty"
                                style={{ flex: '1' }}
                                tabIndex={1002}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={crtnTB}
                                label="Crtn"
                                style={{ flex: '1' }}
                                className="defaultMarginLeft"
                                tabIndex={1003}
                                onKeyDown={onLastTextboxKeyDown} />
                        </div>

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <div className='dRow'>
                            <Textbox
                                ref={searchTB}
                                label="Search"
                                style={{ flex: '1' }}
                                onChange={onSearchTBChange}
                                onKeyDown={onSearchTBKeydown}
                                tabIndex={1004} />
                            <VendorCB
                                ref={vendorCB}
                                tabIndex={1005}
                                style={{ flex: '1' }}
                                className="defaultMarginLeft"
                                onChange={loadData} />
                        </div>

                        <div className='bottomPanel'>
                            <Table
                                ref={tableRef}
                                columns={columns.current}
                                isActive={isActive}
                                onEnterKeyDown={(data) => { props.onItemSelect(data); close(); }}
                                onDoubleClick={(data) => { props.onItemSelect(data); close(); }}
                                editBtn={{ visible: false, onClick: onEditBtnClick }}
                                deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                            <div className="tableFooter">
                                <p><b>Total Items: </b>{totalRejectedItems}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
});

export default RejectedItemsComponent;